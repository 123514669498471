import {createSlice} from "@reduxjs/toolkit";

const filterMetaData = {
  year: { type: 'range', valueKey: 'year' },
  age: { type: 'range', valueKey: 'age' },
  distance: { type: 'range', valueKey: 'distance' },
  mileage: { type: 'range', valueKey: 'mileage' },
  price: { type: 'range', valueKey: 'price' },
  used: { type: 'multiple' },
  make: { type: 'multiple' },
  model: { type: 'multiple' },
  trim: { type: 'multiple' },
  sleep: { type: 'multiple' },
  slideouts: { type: 'multiple' },
  dealers: { type: 'multiple' },
  rooftops: { type: 'multiple' },
  fuelTypes: { type: 'multiple' },
  vehicleCategory: { type: 'single' },
  newOrUsed: { type: 'single' },
}

const initialState = {
  internalAuction: {
    rooftops: []
  },
  buyPage: {
    vehicleCategory: 'Auto',
    dealers: [],
    rooftops: [],
    newOrUsed: '',
    make: [],
    model: [],
    trim: [],
    sleep: [],
    slideouts: [],
    fuelTypes: [],
    age: {
      min: null,
      max: null
    },
    distance: {
      min: null,
      max: null
    },
    year: {
      min: null,
      max: null
    },
  },
  sellPage: {
    vehicleCategory: 'Auto',
    dealers: [],
    rooftops: [],
    newOrUsed: '',
    make: [],
    model: [],
    trim: [],
    sleep: [],
    slideouts: [],
    fuelTypes: [],
    age: {
      min: null,
      max: null
    },
    distance: {
      min: null,
      max: null
    },
    year: {
      min: null,
      max: null
    },
  },
  searchOptions: {}
};

export const searchSlice = createSlice({
  processing: false,
  name: 'searchCriteria',
  initialState,
  reducers: {
    resetSearchOptions: (state, action) => {
      const {payload} = action;
      let newState = {}
      Object.keys(payload).forEach((key) => {
        if (key == 'vehicleCategory') {
        }
        else if (filterMetaData[key]) {
          switch (filterMetaData[key].type) {
            case 'multiple':
              newState[key] = [];
              break;
            case 'range':
              if (payload[key] && payload[key].length > 0) {
                newState[key] = {
                  max: payload[key][0][filterMetaData[key].valueKey],
                  min: payload[key][payload[key].length - 1][filterMetaData[key].valueKey] || 0,
                };
              }
              break;
            default:
              newState[key] = payload[key];
              break;
          }
        }
      });

      return {
        ...state,
        [payload.searchType]: {
          ...state[payload.searchType],
          ...newState
        }
      }
    },
    updateSearchCriteria: (state, action) => {
      const {value, reset} = action.payload
      const searchType = Object.keys(value)[0];
      let typeValues = value[searchType];

      let newValue = reset ? initialState[searchType] : {};
      Object.keys(typeValues).forEach((key) => {
        const meta = filterMetaData[key];
        if (meta) {
          if (meta.type === 'multiple') {
            const currentValues = state[searchType][key] || [];

            if (reset || typeValues[key] == '') {
              newValue[key] = meta.type === 'multiple' ? [] : null;
            } else if (currentValues.includes(typeValues[key])) {
              newValue[key] = currentValues.filter((item) => item !== typeValues[key]);
            } else {
              newValue[key] = [...currentValues, typeValues[key]];
            }
          } else {
            newValue[key] = reset ? null : typeValues[key];
          }
        }
      });

      const newValueWithSearchType = {[searchType]: {
          ...state[searchType],
          ...newValue
        }};

      return {
        ...state,
        ...newValueWithSearchType
      }
    }
  }
})

export const {
  initialize,
  updateSearchCriteria,
  resetSearchOptions
} = searchSlice.actions

export default searchSlice.reducer