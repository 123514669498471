import { configureStore } from '@reduxjs/toolkit';
import { createMiddleware } from "redux-beacon";
import { createReducer } from './rootReducer';
import logger from 'redux-logger';
import API from './utils/API';
import { save, load } from "redux-localstorage-simple"

export const createStore = (eventsMap, ga) => {
  const gaMiddleware = createMiddleware(eventsMap, ga);

  return configureStore({
    reducer: createReducer(eventsMap, ga),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: load({ states: ["searchCriteria"]}),
    middleware: getDefaultMiddleware => {

      return [
        save({ states: ["searchCriteria"]}),
        ...getDefaultMiddleware({
        thunk: {
          extraArgument: API
        }
      }).concat(logger),
        gaMiddleware,
      ]
    }
  })
}