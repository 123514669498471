import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { searchOption, modelOption, trimOption } from 'data/vehicle.actions';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { hasRooftopPermission } from 'utils/User';
import DistanceFilter from './DistanceFilter';
import YearFilter from './YearFilter';
import NewOrUsedFilter from './NewOrUsedFilter';
import PriceFilter from './PriceFilter'; 
import FuelTypeFilter from './FuelTypeFilter';
import OdometerFilter from './OdometerFilter';
import GrossWeightFilter from './GrossWeightFilter';
import AgeFilter from './AgeFilter';
import MakeModelTrimFilter from './MakeModelTrimFilter';
import DealerAndRooftopFilter from './DealerAndRooftopFilter';
import LengthFilter from './LengthFilter';
import SlideOutsFilter from './SlideOutsFilter';
import SleepsFilter from './SleepsFilter';
import styled from "@emotion/styled";
import Grid from '@mui/material/Grid';
import {getSearchCriteria, getUserData} from "data/selectors";
import {updateFilter} from "data/search.actions";

const PREFIX = 'FilterPanel'

const classes = {
    tabs: `${PREFIX}-tabs`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.tabs}`]: {
            textTransform: 'capitalize',
            fontWeight: 600
        },
    }
});

const FilterPanel = ({
    resultType,
    setPage
}) => {
    const searchType = resultType;
    const dispatch = useDispatch();

    const criteria = useSelector( getSearchCriteria( searchType ) );
    const activeCategory = criteria.vehicleCategory;
    const user = useSelector(getUserData);

    const [selectedFilter, setSelectedFilter] = useState('year');

    useEffect(() => {
        if (user) {
            setPage(1);
            const dealerRooftopId = parseInt(user.rooftop_id);

            const params = {
                dealer: user.dealer_id,
                dealerRooftopId,
                searchType,
                activeCategory,
                make: "",
                model: "",
                internalOnly: !hasRooftopPermission(user, dealerRooftopId,
                'purchase_vehicle_from_any_rooftop_owned_by_a_different_dealer')
            };
            dispatch(searchOption(params));
            dispatch(modelOption(params));
            dispatch(trimOption(params));
            if (resultType === 'sellPage') {
                setSelectedFilter('year');
            }
            else if (resultType === 'buyPage') {
                setSelectedFilter('distance');
            }
        }
    }, [dispatch, user, criteria.vehicleCategory, searchType, resultType])

    return (
        <StyledGrid container>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={selectedFilter}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList variant='scrollable' onChange={(e, newValue) => setSelectedFilter(newValue)}>
                            {resultType === 'buyPage' ? <Tab label="Distance" value="distance" className={classes.tabs} /> : ''}
                            <Tab label="Year" value="year" className={classes.tabs} />
                            <Tab label="New or Used" value="neworused" className={classes.tabs} />
                            <Tab label="Price" value="price" className={classes.tabs} />
                            <Tab label="Make" value="make" className={classes.tabs} />
                            <Tab label="Model" value="model" className={classes.tabs} />
                            <Tab label="Trim" value="trim" className={classes.tabs} />
                            {resultType === 'buyPage' ? <Tab label="Dealer" value="dealer" className={classes.tabs} /> : ''}
                            {resultType === 'buyPage' ? <Tab label="Rooftop" value="rooftop" className={classes.tabs} /> : ''}
                            {['RV', 'Auto', 'Other'].includes(activeCategory) ? <Tab label="Fuel Type" value="fueltype" className={classes.tabs} /> : ''}
                            <Tab
                                label={['Boat', 'Personal Watercraft', 'Marine Engine'].includes(activeCategory) ? 'Hours' : 'Odometer'}
                                value="odometer"
                                className={classes.tabs}
                            />
                            {['RV', 'Auto', 'Other'].includes(activeCategory) ? <Tab label="Gross Weight" value="grossweight" className={classes.tabs} /> : ''}
                            {resultType === "sellPage" ? <Tab label="Age" value="age"  className={classes.tabs} /> : ''}
                            {['RV', 'Other'].includes(activeCategory) ? <Tab label="Length" value="length" className={classes.tabs} /> : ''}
                            {['RV', 'Other'].includes(activeCategory) ? <Tab label="Slide-Outs" value="slide-outs" className={classes.tabs} /> : ''}
                            {['RV', 'Other'].includes(activeCategory) ? <Tab label="Sleeps" value="sleeps" className={classes.tabs} /> : ''}
                        </TabList>
                    </Box>
                    {resultType === 'buyPage' ? <DistanceFilter searchType={searchType} /> : ''}
                    <YearFilter searchType={searchType} />
                    <NewOrUsedFilter searchType={searchType} />
                    <PriceFilter searchType={searchType} />
                    <MakeModelTrimFilter searchType={searchType} />
                    {resultType === 'buyPage' ? <DealerAndRooftopFilter searchType={searchType} /> : ''}
                    {['RV', 'Auto', 'Other'].includes(activeCategory) ? <FuelTypeFilter searchType={searchType} /> : ''}
                    <OdometerFilter searchType={searchType} />
                    {['RV', 'Auto', 'Other'].includes(activeCategory) ? <GrossWeightFilter criteria={criteria} /> : ''}
                    <AgeFilter searchType={searchType} />
                    {['RV', 'Other'].includes(activeCategory) ? <LengthFilter searchType={searchType} /> : ''}
                    {['RV', 'Other'].includes(activeCategory) ? <SlideOutsFilter searchType={searchType} /> : ''}
                    {['RV', 'Other'].includes(activeCategory) ? <SleepsFilter searchType={searchType} /> : ''}
                </TabContext>
            </Box>
        </StyledGrid>
    );
}

export default FilterPanel;