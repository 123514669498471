import React, {useEffect, useState} from 'react';
import { TabPanel } from '@mui/lab';
import RangeSlider from 'components/widgets/RangeSlider';
import {useDispatch, useSelector} from 'react-redux';
import {updateFilter} from "data/search.actions";
import {getSearchCriteria, getVehicleOptions} from "data/search.selectors";

const DistanceFilter = ({ searchType }) => {
  const dispatch = useDispatch();

  const relevantCriteria = useSelector( getSearchCriteria( searchType ) );
  const vehicleOption = useSelector(getVehicleOptions);

  const [minDistance, setMinDistance] = useState();
  const [maxDistance, setMaxDistance] = useState();


  useEffect(() => {
    if (vehicleOption) {
      const newMinDistance = vehicleOption.distance?.[vehicleOption?.distance?.length - 1]?.distance;
      const newMaxDistance = vehicleOption.distance?.[0]?.distance;
      setMinDistance(newMinDistance);
      setMaxDistance(newMaxDistance);
    }
  }, [dispatch, vehicleOption?.distance]);

  return (
    <TabPanel value='distance'>
      <RangeSlider
        minValue={minDistance || 0}
        maxValue={maxDistance || 0}
        defaultValue={[relevantCriteria?.distance?.min, relevantCriteria?.distance?.max]}
        labelText={''}
        handleChange={(value) => {
          const [min, max] = value;
          dispatch(updateFilter({
            value: {[searchType]: {distance: {min, max}}}
          }));
        }}
        disabled={true}
      />
    </TabPanel>
  );
};

export default DistanceFilter;