import React from 'react';
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import {useDispatch, useSelector} from 'react-redux';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';
import {getSearchCriteria, getVehicleOptions} from "data/search.selectors";
import {updateFilter} from "data/search.actions";

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const SleepsFilter = ({ searchType }) => {
  const dispatch = useDispatch();

  const criteria = useSelector( getSearchCriteria( searchType ) );
  const vehicleOption = useSelector(getVehicleOptions);

  return (
    <TabPanel value='sleeps'>
      <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                criteria.sleep.length === 0
                  ? classes.btnColor
                  : classes.styledBtn
              }
              color='primary'
              size='large'
              value=''
              onClick={(e) => {
                dispatch(updateFilter({
                  value: {[searchType]: {sleep: e.target.value}}
                }));
              }}
            >
              All
            </StyledOptionButton>
          </Grid>
          {vehicleOption.sleep?.map((sleep) => (
            <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  criteria.sleep.includes(sleep.sleeps)
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={sleep.sleeps}
                onClick={(e) => {
                  dispatch(updateFilter({
                    value: {[searchType]: {sleep: e.target.value}}
                  }));
                }}
              >
                {sleep.sleeps} ({sleep.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
      </StyledFilterGrid>
    </TabPanel>
  );
};

export default SleepsFilter;