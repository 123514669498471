import React, { useEffect } from 'react';
import { TabPanel } from '@mui/lab';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';
import { getSearchCriteria, getVehicleOptions, getLocationData } from "data/selectors";
import { loadRooftopsForFilter } from 'data/location.actions';
import { updateFilter } from "data/search.actions";

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const DealerAndRooftopFilter = ({ searchType }) => {
  const dispatch = useDispatch();
  const criteria = useSelector( getSearchCriteria( searchType ) );
  const vehicleOption = useSelector(getVehicleOptions);
  const location = useSelector(getLocationData);

  let vehicleDealers = criteria.dealers;
  let rooftops = location.rooftopsForFilter;

  useEffect(() => {
    const dealerToken = criteria.dealers.length === 0 ? undefined : criteria.dealers[0]
    dispatch(loadRooftopsForFilter({ dealerToken }));
  }, [criteria.dealers]);

  return (
    <>
      <TabPanel value='dealer'>
        <StyledFilterGrid>
          <Grid container spacing={1}>
            <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  vehicleDealers?.length === 0
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value=''
                onClick={async (e) => {
                  await dispatch(updateFilter({
                    value: {[searchType]: {dealers: ""}}
                  }));

                  await dispatch(loadRooftopsForFilter({ dealerToken: e.target.value }));
                }}
              >
                All
              </StyledOptionButton>
            </Grid>
            {vehicleOption.dealer?.map((dealer) => (
              <Grid className='ml-4 mt-2'>
                <StyledOptionButton
                  variant='contained'
                  className={
                    vehicleDealers?.includes(dealer.dealer_token)
                      ? classes.btnColor
                      : classes.styledBtn
                  }
                  color='primary'
                  size='large'
                  value={dealer.dealer_token}
                  onClick={async (e) => {
                    await dispatch(updateFilter({
                      value: {[searchType]: {dealers: e.target.value}}
                    }));

                    await dispatch(loadRooftopsForFilter({ dealerToken: e.target.value }));
                  }}
                >
                  {dealer.name}
                </StyledOptionButton>
              </Grid>
            ))}
          </Grid>
        </StyledFilterGrid>
      </TabPanel>
      <TabPanel value='rooftop'>
        <StyledFilterGrid>
          <Grid container spacing={1}>
            <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  criteria.rooftops?.length === 0
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value=''
                onClick={async (e) => {
                  await dispatch(updateFilter({
                    value: {[searchType]: {rooftops: e.target.value}}
                  }));
                }}
              >
                All
              </StyledOptionButton>
            </Grid>
            {location.rooftopsForFilter?.map((rooftop) => (
              <Grid className='ml-4 mt-2'>
                <StyledOptionButton
                  variant='contained'
                  className={
                    criteria.rooftops
                      ?.includes(rooftop.dealerRooftopId.toString())
                      ? classes.btnColor
                      : classes.styledBtn
                  }
                  color='primary'
                  size='large'
                  value={rooftop.dealerRooftopId}
                  onClick={async (e) => {
                    await dispatch(updateFilter({
                      value: {[searchType]: {rooftops: e.target.value}}
                    }));
                  }}
                >
                  {rooftop.rooftopName}
                </StyledOptionButton>
              </Grid>
            ))}
          </Grid>
        </StyledFilterGrid>
      </TabPanel>
    </>
  );
};

export default DealerAndRooftopFilter;