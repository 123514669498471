import React, {useEffect, useState} from 'react';
import { TabPanel } from '@mui/lab';
import RangeSlider from 'components/widgets/RangeSlider';
import { useSelector, useDispatch } from 'react-redux';
import { updateFilter } from "data/search.actions";
import {getSearchCriteria, getVehicleOptions} from "../../../data/search.selectors";

const AgeFilter = ({searchType}) => {
  const dispatch = useDispatch();

  const relevantCriteria = useSelector( getSearchCriteria( searchType ) );
  const vehicleOption = useSelector(getVehicleOptions);

  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();

  useEffect(() => {
    if (vehicleOption) {
      const newMinAge = vehicleOption.age?.[vehicleOption.age?.length - 1]?.age;
      const newMaxAge = vehicleOption.age?.[0]?.age;
      setMinAge(newMinAge);
      setMaxAge(newMaxAge);
    }
  }, [dispatch, vehicleOption?.age]);

  return (
    <TabPanel value='age'>
      <RangeSlider
        minValue={ relevantCriteria?.age?.min }
        maxValue={ relevantCriteria?.age?.max }
        defaultValue={[minAge, maxAge]}
        labelText={''}
        handleChange={(value) => {
          const [min, max] = value;
          dispatch(updateFilter({
            value: {[searchType]: {age: {min, max}}}
          }));

        }}
        disabled={true}
      />
    </TabPanel>
  );
};

export default AgeFilter;