import React, {useEffect, useState} from 'react';
import { TabPanel } from '@mui/lab';
import RangeSlider from 'components/widgets/RangeSlider';
import { useSelector, useDispatch } from 'react-redux';
import { updateFilter } from "data/search.actions";
import { getSearchCriteria, getVehicleOptions } from "data/selectors";

const YearFilter = ({ searchType }) => {
  const dispatch = useDispatch();

  const relevantCriteria = useSelector( getSearchCriteria( searchType ) );
  const vehicleOption = useSelector(getVehicleOptions);

  const [minYear, setMinYear] = useState();
  const [maxYear, setMaxYear] = useState();

  useEffect(() => {
    if (vehicleOption && vehicleOption.year) {
      const newMinYear = vehicleOption.year?.[vehicleOption.year?.length - 1]?.year;
      const newMaxYear = vehicleOption.year?.[0]?.year;
      setMinYear(newMinYear);
      setMaxYear(newMaxYear);
    }
  }, [dispatch, vehicleOption?.year]);

  return (
    <TabPanel value='year'>
      <RangeSlider
        minValue={ minYear }
        maxValue={ maxYear }
        defaultValue={[relevantCriteria?.year?.min, relevantCriteria?.year?.max]}
        labelText={''}
        handleChange={(value) => {
          const [min, max] = value;
          dispatch(updateFilter({
            value: {[searchType]: {year: {min, max}}}
          }));
        }}
        disabled={true}
      />
    </TabPanel>
  );
};

export default YearFilter;