import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextField from "components/widgets/TextField";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { formatDollarAmount } from "utils/Formatters";
import { rejectSale } from "data/offer.actions";
import { loadMessageThread } from "data/user.actions";
import Spinner from "components/Spinner";

const DeclineOfferDialog = ({ isOpen, handleClose, offer }) => {
  const dispatch = useDispatch();
  const [declineOfferStep, setDeclineOfferStep] = useState(1);
  const [declineOfferMessage, setDeclineOfferMessage] = useState(offer);

  const { offerRejected, processingReject } = useSelector((state) => state.offer);

  useEffect(() => {
    if (offerRejected && isOpen) {
      dispatch(
        loadMessageThread({ message: { offer_token: offer?.offer_token } })
      );
      toast.success("Offer was Rejected");
      handleClose();
    }
  }, [offerRejected, isOpen]);

  const handleStepOne = (values) => {
    setDeclineOfferMessage(values.declineOfferComment);
    setDeclineOfferStep(2);
  };

  const handleSubmit = () => {
    const data = {
      token: offer?.inventory_token,
      offerToken: offer?.offer_token,
      message: declineOfferMessage,
    };

    dispatch(rejectSale(data));
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { maxWidth: "100vw", width: "510px" } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box
          onClick={() => handleClose()}
          className="float-right cursor-pointer"
        >
          <Typography variant="h4">X</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            {declineOfferStep === 1 && (
              <Formik
                initialValues={{}}
                onSubmit={async (values, actions) => {
                  handleStepOne(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ handleSubmit }) => {
                  return (
                    <div className="w-[378px] mx-auto">
                      <h3 className="uppercase tracking-[3.8px] text-[#253241] text-center font-bold text-[30.9px] ">
                        Decline Offer
                      </h3>

                      <div className="rounded-lg bg-[#F9F9F9] text-center  mt-4 shadow-md">
                        <div className="text-[#213E98] text-[17.8px] font-medium ">
                          Current Offer
                        </div>

                        <div className="text-[#ED1D24] text-[35.7px] font-bold">
                          {formatDollarAmount(
                            offer?.counter_offer_id
                              ? offer?.counter_offer_amount
                              : offer?.offer_amount
                          )}
                        </div>
                      </div>

                      <div className="bg-[#F9F9F9]  mt-6 px-3 py-2 shadow-md">
                        <TextField
                          name="declineOfferComment"
                          multiline
                          rows={5}
                          placeholder="Your comment is here and this is the reason you are countering this offer and also where you put any other issues you may have with this offer."
                          className="w-full border border-[#D2D6DA] bg-white text-[18px]"
                        />
                      </div>

                      <div className="text-white text-medium tracking-[.9px] flex justify-between mt-7">
                        <button
                          onClick={handleClose}
                          className="bg-[#E9392C] shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] w-[181px] rounded-lg py-[15px"
                        >
                          Go Back
                        </button>

                        <button
                          onClick={handleSubmit}
                          className="bg-[#213E98] w-[181px] rounded-lg py-[15px]"
                        >
                          Decline
                        </button>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            )}

            {declineOfferStep === 2 && (
              <div className="w-[378px] mx-auto">
                <h3 className="uppercase tracking-[3.8px] text-[#253241] text-center font-bold text-[30.9px] ">
                  Confirm Decline Offer
                </h3>

                <div className="rounded-lg bg-[#F9F9F9] text-center  mt-4 shadow-md">
                  <div className="text-[#213E98] text-[17.8px] font-medium ">
                    Current Offer
                  </div>

                  <div className="text-[#ED1D24] text-[35.7px] font-bold">
                    {formatDollarAmount(
                      offer?.counter_offer_id
                        ? offer?.counter_offer_amount
                        : offer?.offer_amount
                    )}
                  </div>
                </div>

                <div className="bg-[#F9F9F9]  mt-6 px-3 py-2 shadow-md">
                  <div
                    placeholder="Your comment is here and this is the reason you are countering this offer and also where you put any other issues you may have with this offer."
                    className="w-full border border-[#D2D6DA] bg-white text-[18px] h-[145px] "
                  >
                    <span className="p-2">
                      {declineOfferMessage
                        ? declineOfferMessage
                        : "There is no offer decline message"}
                    </span>
                  </div>
                </div>

                <div className="text-white text-medium tracking-[.9px] flex justify-between mt-7">
                  <button
                    onClick={() => setDeclineOfferStep(1)}
                    className="bg-[#E9392C] shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] w-[181px] rounded-lg py-[15px"
                  >
                    Go Back
                  </button>

                  <button
                    onClick={handleSubmit}
                    className="bg-[#213E98] w-[181px] rounded-lg py-[15px]"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </div>
          <Spinner show={processingReject}/>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DeclineOfferDialog;
