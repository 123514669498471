import { formatDollarAmount } from "utils/Formatters";

const CounterOffers = ({ message, handleEvent, isMine }) => {
  return (
    <>
      <div className={` mt-8 rounded-lg bg-[#F9F9F9] p-[10px] w-full`}>
        <div className="flex items-center w-full justify-between">
          <div className="flex flex-col text-center pl-2">
            <span className="text-[#213E98] text-[18.6px] font-medium mb-1">
              {!isMine ? "You" : message?.seller_rooftop_name} Submitted a
              Counter Offer
            </span>
            <span className="text-[#ED1D24] font-bold text-[37px] leading-8">
              {formatDollarAmount(message?.counter_offer_amount)}
            </span>
          </div>
          <div className="border border-[#D2D6DA] bg-white rounded-lg px-3 py-1 ml-4">
            <p className="text-[#2E2E2E]/50 text-[13.9px] w-[375px] h-[62px] overflow-y-scroll">
              {message?.counter_offer_message}
            </p>
          </div>
        </div>

        <div className="flex mt-5 px-2 justify-between mb-2">
          <button
            onClick={
              !isMine && message?.buyer_approval_date
                ? () => handleEvent("approve_offer")
                : () => handleEvent("approve_counter_offer")
            }
            disabled={
              message?.offer_accepted_date ||
              message?.offer_rejected_date ||
              (isMine && message?.buyer_approval_date) ||
              (!isMine && message?.buyer_approval_date === null)
            }
            className="bg-[#213E98] text-[#F9F9F9] rounded-md shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] font-medium text-[13px] tracking-[.73px] w-[160px] h-[36px] disabled:opacity-20"
          >
            Approve Counter Offer
          </button>
          <button
            onClick={() => handleEvent("decline_offer")}
            disabled={
              message?.offer_accepted_date ||
              message?.offer_rejected_date ||
              (isMine && message?.buyer_approval_date) ||
              (!isMine && message?.buyer_approval_date === null)
            }
            className="bg-[#E9392C] text-[#F9F9F9] rounded-md shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] font-medium text-[13px] tracking-[.73px] w-[160px] h-[36px] disabled:opacity-20"
          >
            Decline Counter Offer
          </button>
          <button
            onClick={() => handleEvent("counter_offer")}
            disabled={
              message?.offer_accepted_date ||
              message?.offer_rejected_date ||
              (isMine && message?.buyer_approval_date) ||
              (!isMine && message?.buyer_approval_date === null)
            }
            className="bg-[#FDE8E9] text-[#ED1D24] rounded-md shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] font-medium text-[13px] tracking-[.73px] w-[160px] h-[36px] disabled:opacity-20"
          >
            Counter Offer
          </button>
          <button
            onClick={() => handleEvent("offer_details")}
            className="bg-[#989BA03D] text-[#213E98] rounded-md shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] font-medium text-[13px] tracking-[.73px] w-[160px] h-[36px]"
          >
            More Information
          </button>
        </div>
      </div>

      {message?.offer_accepted_date && (
        <div className="mt-3 text-xl">Congrats your offer was accpeted</div>
      )}

      {isMine && message?.buyer_approval_date && (
        <div className="mt-3 text-xl">
          You accpeted the seller's counter offer
        </div>
      )}
    </>
  );
};

export default CounterOffers;
