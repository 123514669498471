import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextField from "components/widgets/TextField";
import { toast } from "react-toastify";
import { formatDollarAmount } from "utils/Formatters";
import ConfirmSaleDialog from "components/dialogs/ConfirmSaleDialog";
import { useEffect } from "react";
import { loadMessageThread } from "data/user.actions";
import { counterOffer } from "data/offer.actions";

const ApproveOfferDialog = ({ isOpen, handleClose, vehicle }) => {
  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(counterOffer({counter_id: vehicle?.counter_offer_id}))
    handleClose();
  };

  const {
      processing,
      error,
      offerAccepted,
      processingReject,
      processingCounter
  } = useSelector((state) => state.offer);
  
 useEffect(() => {
  if (offerAccepted && isOpen) {
    dispatch(loadMessageThread({message: {offer_token: vehicle?.offer_token}}))
  }
 }, [offerAccepted, isOpen])
 
  return (
    <>   
    {isOpen === "approve_offer" && <ConfirmSaleDialog 
      isOpen={isOpen} 
      token={vehicle?.inventory_token} 
      offerToken={vehicle?.offer_token} 
      action={vehicle?.offer_rejected_date === null ? 'confirmSale' : 'counterOffer'} 
      handleClose={handleClose}
    /> }

   {isOpen === "approve_counter_offer" && <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { maxWidth: "100vw" } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box onClick={() => handleClose()} className="float-right cursor-pointer">
          <Typography variant="h4">X</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
              <Formik
                initialValues={{}}
                onSubmit={async (values, actions) => {
                  handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ handleSubmit }) => {
                  return (
                    <div className="w-[378px] mx-auto">
                      <h3 className="uppercase tracking-[3.859px] text-[#253241] text-center font-bold text-[30.9px] ">
                        Approve Counter Offer?
                      </h3>

                      <div className="rounded-lg bg-[#F9F9F9] text-center  mt-4 shadow-md">
                        <div className="text-[#213E98] text-[17.8px] font-medium ">
                          Current Counter Offer
                        </div>

                        <div className="text-[#ED1D24] text-[35.7px] font-bold">
                          {formatDollarAmount(vehicle?.counter_offer_amount)}
                        </div>
                      </div>

                      <div className="bg-[#F9F9F9] rounded-lg mt-6 px-3 py-2 shadow-md">
                        <TextField
                          name="approveOfferComment"
                          value={vehicle?.counter_offer_message}
                          readOnly
                          multiline
                          rows={7}
                          placeholder="Your comment is here and this is the reason you are countering this offer and also where you put any other issues you may have with this offer."
                          className="w-full border border-[#D2D6DA] bg-white text-[18px]"
                        />
                      </div>

                      <div className="text-white font-medium tracking-[.9px] flex justify-between mt-6">
                        <button
                          onClick={handleClose}
                          className="bg-[#E9392C] shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] w-[181px] rounded-lg h-[50px]"
                        >
                          Go Back
                        </button>

                        <button
                          onClick={handleSubmit}
                          className="bg-[#213E98] shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] w-[181px] rounded-lg h-[50px]"
                        >
                          Approve
                        </button>
                      </div>
                    </div>
                  );
                }}
              </Formik>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>}
    </>
  );
};

export default ApproveOfferDialog;
