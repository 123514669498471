import React from 'react';
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import {useDispatch, useSelector} from 'react-redux';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';
import {getSearchCriteria, getVehicleOptions} from "data/search.selectors";
import {updateFilter} from "data/search.actions";

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const SlideOutsFilter = ({ searchType }) => {
  const dispatch = useDispatch();

  const criteria = useSelector( getSearchCriteria( searchType ) );
  const vehicleOption = useSelector(getVehicleOptions);

  return (
    <TabPanel value='slide-outs'>
      <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                criteria.slideouts.length === 0
                  ? classes.btnColor
                  : classes.styledBtn
              }
              color='primary'
              size='large'
              value=''
              onClick={(e) => {
                dispatch(updateFilter({
                  value: {[searchType]: {slideouts: e.target.value}}
                }));
              }}
            >
              All
            </StyledOptionButton>
          </Grid>
          {vehicleOption.slideouts?.map((slideout) => (
            <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  criteria.slideouts.includes(slideout.slideouts)
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={slideout.slideouts}
                onClick={(e) => {
                  dispatch(updateFilter({
                    value: {[searchType]: {slideouts: e.target.value}}
                  }));
                }}
              >
                {slideout.slideouts} ({slideout.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
      </StyledFilterGrid>
    </TabPanel>
  );
};

export default SlideOutsFilter;