import { Typography } from "@mui/material";
import { useState } from "react";
import ApproveOfferDialog from "./dialogs/ApproveOfferDialog";
import DeclineOfferDialog from "./dialogs/DeclineOfferDialog";
import CounterOfferDialog from "./dialogs/CounterOfferDialog";
import OfferDetailsDialog from "./dialogs/OfferDetailsDialog";

const TaskOfferPanel = ({ offer, vehicle }) => {
  const [showApproveOfferDialog, setShowApproveOfferDialog] = useState(false);
  const [showDeclineOfferDialog, setShowDeclineOfferDialog] = useState(false);
  const [showOfferDetailsDialog, setShowOfferDetailsDialog] = useState(false);
  const [showCounterOfferDialog, setShowCounterOfferDialog] = useState(false);

  return (
    <div className="bg-white shadow-md w-[420px] p-4 rounded-xl">
      <div className="flex justify-between items-center">
        <Typography
          variant="h3"
          fontSize={29}
          color={"#253241"}
          fontWeight={500}
          textTransform="uppercase"
        >
          Offer Recieved
        </Typography>
        <Typography
          variant="h5"
          fontSize={32}
          color={"#ED1D24"}
          fontWeight={700}
        >
          {offer.amount}
        </Typography>
      </div>

      <div className="text-[#979797] font-medium uppercase flex flex-col font-[Roboto]">
        <span className="text-[15.9px] tracking-[.88px] ">
          From {offer.from_name}
        </span>
        <span className="text-[13.4px] tracking-[.745px] ">
          Rooftop {offer.from_rooftop}
        </span>
      </div>

      <div className="mt-2 flex flex-col font-medium text-[17.7px] tracking-[.98px]">
        <div className="flex text-white justify-between">
          <button
            onClick={() => setShowApproveOfferDialog(true)}
            className="bg-[#213E98] rounded-lg w-[192px] p-3"
          >
            Approve Offer
          </button>
          <button
            // onClick={() => setShowDeclineOfferDialog(true)}
            className="bg-[#E9392C] rounded-lg w-[192px] p-3"
          >
            Decline Offer
          </button>
        </div>
        <div className="flex justify-between mt-2">
          <button
            onClick={() => setShowOfferDetailsDialog(true)}
            className="bg-[#989ba03d] text-[#213E98] rounded-lg w-[192px] p-3"
          >
            More Information
          </button>
          <button
            onClick={() => setShowCounterOfferDialog(true)}
            className="bg-[#FDE8E9] text-[#ED1D24] rounded-lg w-[192px] p-3"
          >
            Counter Offer
          </button>
        </div>
      </div>

      <ApproveOfferDialog
        isOpen={showApproveOfferDialog}
        handleClose={() => setShowApproveOfferDialog(false)}
        offer={offer}
        vehicle={vehicle}
      />

      <DeclineOfferDialog
        isOpen={showDeclineOfferDialog}
        handleClose={() => setShowDeclineOfferDialog(false)}
        offer={offer}
      />

      <CounterOfferDialog
        isOpen={showCounterOfferDialog}
        handleClose={() => setShowCounterOfferDialog(false)}
      />

      <OfferDetailsDialog
        isOpen={showOfferDetailsDialog}
        handleClose={() => setShowOfferDetailsDialog(false)}
        offer={offer}
        vehicle={vehicle}
      />
    </div>
  );
};

export default TaskOfferPanel;
