import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LikesIcon from '@mui/icons-material/FavoriteBorder';
import LikesSelectedIcon from '@mui/icons-material/Favorite';
import MessagesIcon from '@mui/icons-material/MailOutline';
import ProfileMenu from "./ProfileMenu";
import HeadingButtons from "./HeadingButtons";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChangeRooftop from 'components/ChangeRooftop';
import Spinner from 'components/Spinner';
import { updateUserDefaultRooftop } from 'data/user.actions';
import { loadRooftopsForUser } from 'data/location.actions';
import {hasAPermission} from "utils/User";

const PREFIX = 'Header';

const classes = {
  logo: `${PREFIX}-logo`,
  linksContainer: `${PREFIX}-linksContainer`,
  linkContainer: `${PREFIX}-linkContainer`,
  menu: `${PREFIX}-menu`,
  menuItem: `${PREFIX}-menuItem`,
  menuImage: `${PREFIX}-menuImage`,
  activeLink: `${PREFIX}-activeLink`,
  profile: `${PREFIX}-profile`,
  likes: `${PREFIX}-likes`,
  messages: `${PREFIX}-messages`,
  signUp: `${PREFIX}-signUp`,
  btn: `${PREFIX}-btn`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  activeAuctionsLink: `${PREFIX}-activeAuctionsLink`,
  innerBox: `${PREFIX}-innerBox`,
  outerBox: `${PREFIX}-outerBox`,
  coin: `${PREFIX}-coin`,
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    background: theme.palette.background.default,
    boxShadow: '0px 9px 10px rgb(0 0 0 / 5%)',
    [`& .${classes.logo}`]: {
    width: 375,
    margin: '0 3em'
    },
    [`& .${classes.linksContainer}`]: {
      padding: '2em 0 1em 0',
    },
    [`& .${classes.linkContainer}`]: {
      marginTop: 15,
      marginLeft: 35,
      position: 'relative'
    },
    [`& .${classes.menu}`]: {
      float: 'right',
    },
    [`& .${classes.activeLink}`]: {
      paddingBottom: 5,
      color: theme.palette.secondary.main,
      boxShadow: '0px 9px 10px rgb(0 0 0 / 5%)',
    },
    [`& .${classes.activeAuctionsLink}`]: {
      paddingBottom: 5,
      color: theme.palette.secondary.main,
    },
    [`& .${classes.auctionsLink}`]: {
      paddingBottom: 5,
    },
    [`& .${classes.profile}`]: {
      float: 'right',
    },
    [`& .${classes.likes}`]: {
      float: 'right',
      paddingTop: 2,
      marginTop: 20,
      marginRight: 20
    },
    [`& .${classes.messages}`]: {
      float: 'right',
      paddingTop: 2,
      marginTop: 20,
    },
    [`& .${classes.outerBox}`]: {
      position: 'relative',
    },
    [`& .${classes.innerBox}`]: {
      position: 'absolute',
      left: 45,
      background: theme.palette.secondary.main,
      textAlign: 'center',
      borderRadius: '50%',
    },
    [`& .${classes.coin}`]: {
      position: 'absolute',
      left: 70,
      background: theme.palette.secondary.main,
      textAlign: 'center',
      borderRadius: '50%',
    },
  }
});

function CustomLink({ children, to, ...props }) {
  let location = useLocation();
  let match = location?.pathname?.startsWith(to);

  return (
    <div>
      <Link
        className={match ? classes.activeLink : ''}
        to={to}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
}
const Header = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.authentication);
  const {processing: processingLocations, locations, rooftopsForUser,  error: locationError} = useSelector(state => state.location)
  const { transactionCount } = useSelector(state => state.transaction);
  const { activeAuctionCount } = useSelector(state => state.auction);
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState();
  const [auctionSelected, setAuctionSelected] = useState(false)


  useEffect(() => {
    if (location?.pathname?.startsWith('/auction')) {
      setAuctionSelected(true);
    }
    else {
      setAuctionSelected(false);
    }
  }, [location]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  useEffect(() => {
    if (user && rooftopsForUser === undefined) {
        dispatch(loadRooftopsForUser({
          userId: user.id
        }))
    } 
}, [user, rooftopsForUser, dispatch]);

  const handleChangeRooftop = (values) => {
    const newValues = {
      userId: user.id,
      default_rooftop: values.rooftop,
    }
    dispatch(updateUserDefaultRooftop(newValues));
  }

  return (
    <StyledGrid container>
      <Grid item xs={12} lg={4} sx={{display:'flex', justifyContent: {xs:'center', sm:'normal'}}}>
        <img
          className={classes.logo}
          alt="Reinvent Exchange Logo"
          src="/static/images/reinvent-exchange.jpeg"
        />
      </Grid>

      {user && (
        <Grid item xs={12} lg={8} className={classes.linksContainer}>
          <Grid container>
            <Grid item md={8} lg={6}>
              <Grid container>
                <Grid item xs={4} sm={2} lg={1} className={classes.linkContainer}>
                  <CustomLink to='/buy' ><Typography variant='h5'>Buy</Typography></CustomLink>
                </Grid>
                {(user.isDealerAdmin || (hasAPermission(user, 'sell_vehicle'))) &&
                  <Grid item xs={4} sm={2} lg={1} className={classes.linkContainer}>
                    <CustomLink to='/sell'><Typography variant='h5'>Sell</Typography></CustomLink>
                  </Grid>
                }
                <Grid item xs={4} sm={2} lg={2} className={classes.linkContainer}>
                  <Typography
                    aria-haspopup="true"
                    aria-expanded={anchorEl !== undefined ? 'true' : undefined}
                    onMouseEnter={handleClick}
                    className={auctionSelected ? classes.activeAuctionsLink : ''}
                  >
                    <Typography variant='h5'>Auctions</Typography>
                  </Typography>
                  {activeAuctionCount > 0 && (
                    <Box className={classes.coin} sx={{top: {xs: '-10px', sm: '-15px'}, width: {xs: '20px', sm: '30px'}, padding: {xs: '1px', sm: '4px'}}}>
                      <Typography variant='body1' color='text.white' sx={{fontSize: {xs: '12px', sm: '16px'}, fontWeight: {xs: 'bold', sm: 'normal'}}}>
                        {activeAuctionCount ? activeAuctionCount : 0}
                      </Typography>
                    </Box>
                  )}
                  <Menu
                    anchorEl={anchorEl}
                    open={anchorEl !== undefined}
                    onClose={() => setAnchorEl(undefined)}
                    MenuListProps={{ onMouseLeave: handleClose }}
                  >
                    <MenuItem
                        onClick={handleClose}
                    >
                      <CustomLink to='/auctions'>
                        <Typography variant='h5'>
                          Active Auctions
                        </Typography>
                      </CustomLink>
                    </MenuItem>
                    <MenuItem
                        onClick={handleClose}
                    >
                        <CustomLink to='/auctions/mine'>
                          <Typography variant='h5'>
                            My Auctions
                          </Typography>
                        </CustomLink>
                    </MenuItem>
                    <MenuItem
                        onClick={handleClose}
                    >
                      <CustomLink to='/auctions/internal'>
                        <Typography variant='h5'>
                          Internal Auctions
                        </Typography>
                      </CustomLink>
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item xs={4} sm={2} lg={1.5} className={classes.linkContainer}>
                  <Box className={classes.outerBox}>
                    <CustomLink to='/tasks'><Typography variant='h5'>Tasks</Typography></CustomLink>
                    {transactionCount > 0 && (
                      <Box className={classes.innerBox} sx={{top: {xs: '-10px', sm: '-15px'}, width: {xs: '20px', sm: '30px'}, padding: {xs: '1px', sm: '4px'}}}>
                        <Typography variant='body1' color='text.white' sx={{fontSize: {xs: '12px', sm: '16px'}, fontWeight: {xs: 'bold', sm: 'normal'}}}>
                          {transactionCount}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={4} sm={2} lg={0} className={classes.linkContainer}>
                  <CustomLink to='/reports'><Typography variant='h5'>Reports</Typography></CustomLink>
                </Grid>
              </Grid>
              
            </Grid>
            
            <Grid item md={6}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{marginTop: {xs: '20px', md: '-15px'}, marginLeft: {xs: '25px', md: '20px' }}}>
                {rooftopsForUser?.length > 1 && <ChangeRooftop
                    initialValue={{ rooftop: parseInt(user?.rooftop_id)}}
                    rooftops={rooftopsForUser}
                    handleSubmit={(values) => handleChangeRooftop(values)}
                    label={"Current Rooftop"}
                  />}
                </Grid>
                <Grid item md={2} sx={{minWidth: {xs: '20px', md: '100px'}, marginLeft: {xs: '35px', md: '20px' }}}>
                  <Link to="/messages">
                    <MessagesIcon className={classes.messages} alt="Messages" />
                  </Link>
                  <Link to="/likes">
                    {location.pathname.startsWith('/likes') ?
                      <LikesSelectedIcon className={classes.likes} alt="Likes" /> :
                      <LikesIcon className={classes.likes} alt="Likes" />
                    }
                  </Link>
                </Grid>
                <Grid item xs={6} sm={3} md={1} className={classes.profile}>
                  <ProfileMenu />
                </Grid>
              </Grid>
            </Grid>  
          </Grid>
        </Grid>
      )}

      {!user && (<Grid item xs={8}><HeadingButtons /></Grid>)}
      <Spinner show={ processingLocations } />
    </StyledGrid >
  )
}

export default Header;