import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Image from 'components/widgets/Image';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CategoryPanel from './CategoryPanel';
import { updateFilter, updateFilterOptions } from "data/search.actions";
import { vehicleSearch, getCategoryCount } from 'data/vehicle.actions';
import {getVehicleSearchData, getSearchCriteria, getVehicleOptions, getUserData} from "data/selectors";

const PREFIX = 'SearchBox'

const classes = {
  btn: `${PREFIX}-btn`,
  btnBackground: `${PREFIX}-btnBackground`,
  select: `${PREFIX}-select`,
  image: `${PREFIX}-image`,
  comboBox: `${PREFIX}-comboBox`,
  option: `${PREFIX}-option`,
  downArrow: `${PREFIX}-downArrow`
}

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.card}`]: {
      marginBottom: 30,
      boxShadow: '0px 4px 31px rgb(0 0 0 / 5%)',
      borderRadius: 10,
    },
    [`& .${classes.btn}`]: {
      textTransform: 'capitalize',
      width: 100,
      height: 45,
    },
    [`& .${classes.btnBackground}`]: {
      marginRight: '2px',
      textTransform: 'capitalize',
      background: theme.palette.secondary.main,
      width: 100,
      height: 45,
    },
    [`& .${classes.select}`]: {
      background: theme.palette.primary.main,
      height: 45,
      color: '#ffffff',
      padding: 12,
      borderRadius: '4px 0px 0px 4px',
    },
    [`& .${classes.comboBox}`]: {
      display: 'flex',
      // width: '75px'
    },
    [`& .${classes.image}`]: {
      aspectRatio: 'auto',
      height: 22,
    },
    [`& .${classes.option}`]: {
      marginLeft: 10
    },
    [`& .${classes.downArrow}`]: {
      marginLeft: 30
    },
  }
});

const SearchBox = ({
  resultType
}) => {
  const dispatch = useDispatch();

  const relevantCriteria = useSelector( getSearchCriteria( resultType ) );
  const vehicleOptions = useSelector(getVehicleOptions);
  const search = useSelector(getVehicleSearchData);
  const user = useSelector(getUserData);

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    dispatch(getCategoryCount(resultType));
  }, [
    dispatch,
    resultType,
    user ? user.rooftopId : undefined
  ]);

  return (
    <StyledGrid container>
        <Box sx={{display: 'flex', flexDirection: { xs: "column", md: "row"}, width: '1', mt: 4}}>
          <Paper
            component="form"
            sx={{ display: 'inline-flex', flex: 1, alignItems: 'center'}}
          >
            <Box
                className={classes.select}
                onClick={() => setIsShow(!isShow)}
            >
              <div className={classes.comboBox}>
                <Image
                  src={`/static/images/${relevantCriteria?.vehicleCategory}.png`}
                  alt="Auto"
                  className={classes.image}
                />
                <label className={classes.option}>{relevantCriteria?.vehicleCategory}</label>
                {isShow ?
                    <KeyboardArrowUpIcon className={classes.downArrow} /> :
                    <KeyboardArrowDownIcon className={classes.downArrow} />
                }
              </div>
            </Box>
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Vehicles By Make, Model, Year OR VIN"
              inputProps={{
                'aria-label': 'search vehicles'
              }}
              onChange={(e) => {
                dispatch(updateFilter({
                  value: {[resultType]: {vehicleSearch: e.target.value}},
                }));
              }}
              value={relevantCriteria.vehicleSearch}
            />
          </Paper>
          <Box sx={{
              display: 'flex',
              marginLeft: '5px',
              marginTop:  {xs: '5px', md: '0px' }
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.btnBackground}
              onClick={() => dispatch(vehicleSearch({
                ...relevantCriteria,
                searchType: resultType
              }))}
            >
              Search
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.btn}
              onClick={() => dispatch(updateFilterOptions({
                ...vehicleOptions,
                searchType: resultType
              }))}
            >
              Reset
            </Button>
          </Box>
        </Box>
        <Grid item xs={12}>
          <CategoryPanel
            isShow={isShow}
            handleCategoryChange={(value) => {
              setIsShow(false);
              dispatch( updateFilter({
                value: {[resultType]: {vehicleCategory: value}},
              }));
            }}
            categories={search.categoryCount}
            selectedCategory={relevantCriteria.vehicleCategory}
          />
        </Grid>
    </StyledGrid>
  );
}

export default SearchBox;