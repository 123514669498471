import {createAsyncThunk  } from "@reduxjs/toolkit";
import { updateSearchCriteria, resetSearchOptions } from './search.slice';

export const updateFilter = createAsyncThunk('search/updateFilter', async (values, {extra, dispatch}) => {
    await dispatch( updateSearchCriteria(values) );
});

export const updateFilterOptions = createAsyncThunk('search/updateFilterOptions', async (values, {extra, dispatch}) => {
    dispatch( resetSearchOptions(values) );
});
