import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';
import {updateFilter} from "data/search.actions";
import {getSearchCriteria, getVehicleOptions} from "data/search.selectors";

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const newUsedCriteria = [
  { label: 'All', value: '' },
  { label: 'New', value: 'New' },
  { label: 'Used', value: 'Used' },
];

const YearFilter = ({ searchType }) => {
  const dispatch = useDispatch();
  const criteria = useSelector( getSearchCriteria( searchType ) );

  return (
    <TabPanel value='neworused'>
      <StyledFilterGrid>
        <Grid container spacing={1}>
          {newUsedCriteria.map((item) => (
            <Grid key={`new-used-${item.value}`} className='ml-4'>
              <StyledOptionButton
                variant='contained'
                className={
                  item.value === criteria.newOrUsed
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={item.value}
                onClick={(e) => {
                  dispatch(updateFilter({
                    value: {[searchType]: {newOrUsed: e.target.value }}
                  }));
                }}
              >
                {item.label}
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
      </StyledFilterGrid>
    </TabPanel>
  );
};

export default YearFilter;