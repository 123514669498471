import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux';
import GoogleAnalyticsGtag from '@redux-beacon/google-analytics-gtag';
import './index.css'
import AppRouter from './router'
import reportWebVitals from './reportWebVitals'
import theme from './styles/theme'
import { CssBaseline, ThemeProvider } from "@mui/material"
import ErrorBoundary from "./ErrorBoundry";
import { createStore } from './store';
import { googleAnalyticsTrackingId } from './config'
import { createEventMap } from "./eventMap";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Messaging from "./messaging";

const container = document.getElementById('root');
const root = createRoot(container);

const ga = GoogleAnalyticsGtag(googleAnalyticsTrackingId);

root.render(
  <Provider store={createStore(createEventMap(), ga)}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <ToastContainer />
          <CssBaseline />
          <AppRouter />
          {/*<Messaging />*/}
        </ErrorBoundary>
      </ThemeProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
