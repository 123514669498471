import * as yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextField from "components/widgets/TextField";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { formatDollarAmount } from "utils/Formatters";
import { counterOffer } from "data/offer.actions";
import { loadMessageThread } from "data/user.actions";
import Spinner from "components/Spinner";

const CounterOfferDialog = ({
  isOpen,
  handleClose,
  currentOffer,
  initialCounterValues = {},
}) => {

  const validationSchema = yup.object().shape({
    counterAmount: yup
      .number()
      .required("Please enter a counter offer amount"),
      // .moreThan(currentOffer?.offer_amount, `Counter offer must more than current offer: ${formatDollarAmount(currentOffer?.offer_amount)}`),
      counterMessage: yup.string().max(255, 'Counter offer message can\'t be more than 255 characters'),
  });
  
  const dispatch = useDispatch()
  const [counterOfferStep, setCounterOfferStep] = useState(1);
  const [showErrors, setShowErrors] = useState(false);
  const [offerData, setOfferData] = useState(initialCounterValues);

  const { processingCounter, offerCounted } = useSelector((state) => state.offer)

  const handleStepOne = (values) => {
    const newValues = {
      counterMessage: values.counterMessage,
      counterAmount: values.counterAmount,
    };
    setOfferData(newValues);
    setCounterOfferStep(2);
  };

  const handleSubmit = () => {
    const data = {
      offerToken: currentOffer?.offer_token,
      counterAmount: offerData.counterAmount,
      counterMessage: offerData?.counterMessage
    }
    dispatch(counterOffer(data))
  };

  useEffect(() => {
    if (offerCounted && isOpen) {
     dispatch(loadMessageThread({message: {offer_token: currentOffer?.offer_token}}))
     setCounterOfferStep(3)
    }
  }, [offerCounted, isOpen])
  
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { maxWidth: "100vw", width: "510px" } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box onClick={handleClose} className='float-right cursor-pointer'>
          {counterOfferStep !== 3 && <Typography variant="h4">X</Typography>}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            {counterOfferStep === 1 && (
              <Formik
                initialValues={offerData}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                  handleStepOne(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ handleSubmit, errors, touched }) => {
                  return (
                    <div className="w-[378px] mx-auto">
                      <h3 className="uppercase tracking-[3.8px] text-[#253241] text-center font-bold text-[30.9px] ">
                        Counter Offer
                      </h3>

                      <div className="rounded-lg bg-[#F9F9F9] text-center  mt-4 shadow-md">
                        <div className="text-[#213E98] text-[17.8px] font-medium ">
                          Enter Counter Offer
                        </div>

                        <TextField
                          name="counterAmount"
                          placeholder="Type offer here"
                          sx={{ fontSize: "24px" }}
                          className=" text-[24px] focus:outline-none placeholder:text-center"
                        />
                      </div>

                      {errors.counterAmount &&
                        showErrors &&
                        !touched.counterAmount && (
                          <div className="text-danger text-center mt-[2px]">
                            {errors.counterAmount}
                          </div>
                        )}

                      <div className="bg-[#F9F9F9]  mt-6 px-3 py-2 shadow-md text-center">
                        <TextField
                          name="counterMessage"
                          multiline
                          rows={5}
                          placeholder="Leave a comment as to why you countered"
                          className="w-full border border-[#D2D6DA] bg-white text-[18px]"
                        />
                      </div>

                      <div className="text-white text-medium tracking-[.9px] flex justify-between mt-7">
                        <button
                          onClick={handleClose}
                          className="bg-[#E9392C] shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] w-[181px] rounded-lg py-[15px"
                        >
                          Cancel
                        </button>

                        <button
                          onClick={() => {
                            handleSubmit();
                            setShowErrors(true);
                          }}
                          className="bg-[#213E98] w-[181px] rounded-lg py-[15px]"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            )}
            {counterOfferStep === 2 && (
              <Formik
                initialValues={{}}
                onSubmit={async (values, actions) => {
                  handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ handleSubmit }) => {
                  return (
                    <div className="w-[378px] mx-auto">
                      <h3 className="uppercase tracking-[3.8px] text-[#253241] text-center font-bold text-[30.9px] ">
                        Approve Counter
                      </h3>

                      <div className="rounded-lg bg-[#F9F9F9] text-center  mt-4 shadow-md">
                        <div className="text-[#213E98] text-[17.8px] font-medium ">
                          Current Counter Offer
                        </div>

                        <div className="text-[#ED1D24] text-[35.7px] font-bold">
                          {formatDollarAmount(offerData?.counterAmount)}
                        </div>
                      </div>

                      <div className="bg-[#F9F9F9]  mt-6 px-3 py-2 shadow-md">
                        <TextField
                          name="approveCounterComment"
                          multiline
                          value={offerData?.counterMessage}
                          readOnly
                          rows={5}
                          placeholder="Your comment is here and this is the reason you are countering this offer and also where you put any other issues you may have with this offer."
                          className="w-full border border-[#D2D6DA] bg-white text-[18px]"
                        />
                      </div>

                      <div className="text-white text-medium tracking-[.9px] flex justify-between mt-7">
                        <button
                          onClick={() => setCounterOfferStep(1)}
                          className="bg-[#E9392C] shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] w-[181px] rounded-lg py-[15px"
                        >
                          Edit Counter
                        </button>

                        <button
                          onClick={handleSubmit}
                          className="bg-[#213E98] w-[181px] rounded-lg py-[15px]"
                        >
                          Approve
                        </button>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            )}
            {counterOfferStep === 3 && (
              <div>
                <h3 className="text-[30.87px] font-bold tracking-[3.85px] uppercase text-center mb-5">
                  Counter Sent!
                </h3>

                <button className="bg-[#213E98] rounded-lg shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] font-medium tracking-[.9px] w-[255px] h-[50px] text-white mr-3">
                  View In Messanger
                </button>

                <button
                  onClick={handleClose}
                  className="bg-[#E9392C] rounded-lg shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] font-medium tracking-[.9px] w-[147px] h-[50px] text-white"
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </DialogContentText>
      </DialogContent>
      <Spinner show={processingCounter}/>
    </Dialog>
  );
};

export default CounterOfferDialog;
