import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";

const PREFIX = 'CategoryPanel'

const classes = {
    categoryCard: `${PREFIX}-categoryCard`,
    cardContent: `${PREFIX}-cardContent`,
    img: `${PREFIX}-img`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.categoryCard}`]: {
            background: 'rgba(156, 163, 177, 0.17)',
            borderRadius: 7,
        },
        [`& .${classes.cardContent}`]: {
            marginTop: 15,
            fontSize: 12
        },
        [`& .${classes.img}`]: {
            aspectRatio: 'auto',
            height: 30
        },
    }
});

const CategoryPanel = ({
    isShow,
    handleCategoryChange,
    categories,
    selectedCategory
}) => {
    const getCount = (defaultCategory) => {
        if(categories?.length > 0){
            const filterArr = categories.filter(cat => cat.vehicle_category === defaultCategory);
            if(filterArr?.length > 0) {
                if(filterArr[0]?.count){
                    return filterArr[0]?.count;
                }else{
                    return 0;
                }
            }
        }
    }

    return (
        <>
            {isShow && (
                <StyledGrid container>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('Auto')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'Auto' ? 'Auto1' : 'Auto'}.png`}
                                                    alt="Auto"
                                                    className={classes.img}
                                                />
                                                <label>Auto ({getCount('Auto')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('RV')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'RV' ? 'RV1' : 'RV'}.png`}
                                                    alt="RV"
                                                    className={classes.img}
                                                />
                                                <label>RV ({getCount('RV')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('Boat')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'Boat' ? 'Boat1' : 'Boat'}.png`}
                                                    alt="Boat"
                                                    className={classes.img}
                                                />
                                                <label>Boat ({getCount('Boat')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('Motorcycle')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'Motorcycle' ? 'Motorcycle1' : 'Motorcycle'}.png`}
                                                    alt="Motorcycle"
                                                    className={classes.img}
                                                />
                                                <label>Motorcycle ({getCount('Motorcycle')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('eBike')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'eBike' ? 'eBike1' : 'eBike'}.png`}
                                                    alt="eBike"
                                                    className={classes.img}
                                                />
                                                <label>Ebike ({getCount('eBike')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('ATV')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'ATV' ? 'ATV1' : 'ATV'}.png`}
                                                    alt="ATV"
                                                    className={classes.img}
                                                />
                                                <label>Atv ({getCount('ATV')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('Personal Watercraft')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'Personal Watercraft' ? 'Personal Watercraft1' : 'Personal Watercraft'}.png`}
                                                    alt="Personal Watercraft"
                                                    className={classes.img}
                                                />
                                                <label>Personal Watercraft ({getCount('Personal Watercraft')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('Snowmobile')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'Snowmobile' ? 'Snowmobile1' : 'Snowmobile'}.png`}
                                                    alt="Snowmobile"
                                                    className={classes.img}
                                                />
                                                <label>Snow Mobile ({getCount('Snowmobile')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('Marine Engine')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'Marine Engine' ? 'Marine Engine1' : 'Marine Engine'}.png`}
                                                    alt="Marine Engine"
                                                    className={classes.img}
                                                />
                                                <label>Marine ({getCount('Marine Engine')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('UTV')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'UTV' ? 'UTV1' : 'UTV'}.png`}
                                                    alt="UTV"
                                                    className={classes.img}
                                                />
                                                <label>UTV ({getCount('UTV')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('Golf')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'Golf' ? 'Golf1' : 'Golf'}.png`}
                                                    alt="Golf"
                                                    className={classes.img}
                                                />
                                                <label>Golf ({getCount('Golf')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => handleCategoryChange('Other')}>
                                    <Card className={classes.categoryCard}>
                                        <CardContent className={classes.cardContent}>
                                            <center>
                                                <Image
                                                    src={`/static/images/${selectedCategory === 'Other' ? 'Other1' : 'Other'}.png`}
                                                    alt="Other"
                                                    className={classes.img}
                                                />
                                                <label>Other ({getCount('Other')})</label>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </StyledGrid>
            )}
        </>
    )
}

export default CategoryPanel;