import React, {useEffect, useState} from 'react';
import { TabPanel } from '@mui/lab';
import RangeSlider from 'components/widgets/RangeSlider';
import Grid from '@mui/material/Grid';
import {useDispatch, useSelector} from "react-redux";
import {getSearchCriteria, getVehicleOptions} from "data/search.selectors";
import {updateFilter } from "data/search.actions";

const LengthFilter = ({ searchType }) => {
  const dispatch = useDispatch();

  const relevantCriteria = useSelector( getSearchCriteria( searchType ) );
  const vehicleOption = useSelector(getVehicleOptions);

  const [minLength, setMinLength] = useState();
  const [maxLength, setMaxLength] = useState();

  useEffect(() => {
    if (vehicleOption) {
      const newMinLength = vehicleOption.length?.[vehicleOption.length?.length - 1]?.length;
      const newMaxLength = vehicleOption.length?.[0]?.length;
      setMinLength(newMinLength);
      setMaxLength(newMaxLength);
    }
  }, [dispatch, vehicleOption?.length]);

  return (
    <TabPanel value='length'>
      <Grid container>
        <Grid xs={12}>
          <RangeSlider
            minValue={minLength || 0}
            maxValue={maxLength || 0}
            defaultValue={[
              relevantCriteria.length?.min,
              relevantCriteria.length?.max,
            ]}
            labelText={''}
            handleChange={(value) => {
              const [min, max] = value;
              dispatch(updateFilter({
                value: {[searchType]: {length: {min, max}}}
              }));
            }}
            disabled={true}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default LengthFilter;