import React, { useEffect } from 'react';
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { modelOption, trimOption } from 'data/vehicle.actions';
import { selectMultipleOption } from 'utils/Filter';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';
import { getUserData, getSearchCriteria, getVehicleSearchData } from "data/selectors";
import { updateFilter } from "data/search.actions";

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const MakeModelTrimFilter = ({ searchType }) => {
  const dispatch = useDispatch();
  const criteria = useSelector( getSearchCriteria( searchType ) );
  const search = useSelector( getVehicleSearchData );
  const user = useSelector(getUserData);
  const { vehicleOption, modelOptions, trimOptions } = search;

  useEffect(() => {
    if (user) {
      const params = {
        dealer: user?.dealer_id,
        dealerRooftopId: parseInt(user.rooftop_id),
        searchType,
        activeCategory: criteria.vehicleCategory,
        make: `'${criteria.make.join(',')}'`,
      };

      dispatch(modelOption(params));
    }
  }, [user, criteria.make]);

  useEffect(() => {
    if (user) {
      const params = {
        dealer: user?.dealer_id,
        dealerRooftopId: parseInt(user.rooftop_id),
        searchType,
        activeCategory: criteria.vehicleCategory,
        make: `'${criteria.make.join(',')}'`,
        model: `'${criteria.model.join(',')}'`,
      };

      dispatch(trimOption(params));
    }
  }, [user, criteria.model]);

  return (
    <>
      <TabPanel value='make'>
        <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                   criteria.make?.length === 0
                      ? classes.btnColor
                      : classes.styledBtn
                    }
                    color='primary'
                    size='large'
                    value=''
                    onClick={(e) => {
                      dispatch(updateFilter({
                        value: {[searchType]: {make: e.target.value}}
                      }));
                    }}
                    >
              All
            </StyledOptionButton>
          </Grid>
          {vehicleOption.makes?.map((make) => (
              <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  criteria.make?.includes(make.make)
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={make.make}
                onClick={(e) => {
                  dispatch(updateFilter({
                    value: {[searchType]: {make: e.target.value}}
                  }));
                }}
              >
                {make.make} ({make.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
          </StyledFilterGrid>
      </TabPanel>
      <TabPanel value='model'>
        <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                criteria.model?.length === 0
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value=''
                onClick={(e) => {
                  dispatch(updateFilter({
                    value: {[searchType]: {model: e.target.value}}
                  }));
                }}
                >
              All
            </StyledOptionButton>
          </Grid>
          {modelOptions?.model?.map((model) => (
              <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  criteria.model?.includes(model.model)
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={model.model}
                onClick={(e) => {
                  dispatch(updateFilter({
                    value: {[searchType]: {model: e.target.value}}
                  }));
                }}
                >
                {model.model} ({model.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
          </StyledFilterGrid>
      </TabPanel>
      <TabPanel value='trim'>
        <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                criteria.trim?.length === 0
                      ? classes.btnColor
                      : classes.styledBtn
                    }
                    color='primary'
                    size='large'
                    value=''
                    onClick={(e) => {
                      dispatch(updateFilter({
                        value: {[searchType]: {trim: e.target.value}}
                      }));
                    }}
                    >
              All
            </StyledOptionButton>
          </Grid>
          {trimOptions?.trims?.map((trim) => (
              <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  criteria.trim?.includes(trim.name)
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={trim.name}
                onClick={(e) => {
                  dispatch(updateFilter({
                    value: {[searchType]: {trim: e.target.value}}
                  }));
                }}
                >
                  {trim.name} ({trim.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
          </StyledFilterGrid>
      </TabPanel>
    </>
  );
};

export default MakeModelTrimFilter;