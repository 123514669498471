import { React, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import RangeSlider from 'components/widgets/RangeSlider';
import { Typography, Checkbox, Divider } from '@mui/material';
import {updateFilter} from "data/search.actions";
import {getSearchCriteria, getVehicleOptions} from "data/selectors";

const PriceFilter = ({ searchType }) => {
  const dispatch = useDispatch();
  const relevantCriteria = useSelector( getSearchCriteria( searchType ) );
  const vehicleOption = useSelector(getVehicleOptions);

  const [isPrice, setIsPrice] = useState(true);
  const [isKbbPrice, setIsKbbPrice] = useState(true);

  return (
    <TabPanel value='price'>
      <Grid container justifyContent={'space-around'}>
        <Grid xs={5} p={2}>
          <Grid container justifyContent={'space-between'}>
            <Grid>
              <Typography variant='h4'>Price</Typography>
            </Grid>
            <Grid>
              <Checkbox
                onChange={(e) => {
                  setIsPrice(e.target.checked);
                }}
                defaultChecked={isPrice}
              />
            </Grid>
          </Grid>
          <RangeSlider
            minValue={
              vehicleOption.price?.[vehicleOption.price?.length - 1]?.price
            }
            maxValue={vehicleOption.price?.[0]?.price}
            defaultValue={[relevantCriteria?.price?.min, relevantCriteria?.price?.max]}
            labelText={'$'}
            handleChange={(value) => {
              const [min, max] = value;
              if (isPrice) {
                dispatch(updateFilter({
                  value: {[searchType]: {price: {min, max}}}
                }));
              }
            }}
            disabled={isPrice}
          />
        </Grid>
        <Divider orientation='vertical' variant='middle' flexItem />
        <Grid xs={5} p={2}>
          <Grid container justifyContent={'space-between'}>
            <Grid>
              <Typography variant='h4'>KBB Wholesale Price</Typography>
            </Grid>
            <Grid>
              <Checkbox
                onChange={(e) => {
                  setIsKbbPrice(e.target.checked);
                }}
                defaultChecked={isKbbPrice}
                disabled={relevantCriteria?.vehicleCategory !== 'RV'}
              />
            </Grid>
          </Grid>
          <RangeSlider
            minValue={
              vehicleOption.kbbPrice?.[vehicleOption.kbbPrice?.length - 1]
                ?.kbb_auction_value !== null
                ? vehicleOption.kbbPrice?.[vehicleOption.kbbPrice?.length - 1]
                    ?.kbb_auction_value
                : 0
            }
            maxValue={vehicleOption.kbbPrice?.[0]?.kbb_auction_value}
            defaultValue={[
              relevantCriteria?.kbbPrice?.min || 0,
              relevantCriteria?.kbbPrice?.max,
            ]}
            labelText={'$'}
            handleChange={(value) => {
              const [min, max] = value;
              if (isKbbPrice) {
                dispatch(updateFilter({
                  value: {[searchType]: {kbbPrice: {min, max}}}
                }));
              }
            }}
            disabled={relevantCriteria?.vehicleCategory === 'RV' ? isKbbPrice : false}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default PriceFilter;