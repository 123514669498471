import { Typography, Avatar } from "@mui/material";
import PinIcon from "components/TextEditor/icons/pin.svg";
import UnpinIcon from "components/TextEditor/icons/unpinned.svg";
import { formatDollarAmount, formatFromNow } from "utils/Formatters";
import { getInitials } from "utils/User";

export default function MessageList({
  currentThread,
  isMine,
  pinned,
  message,
  handleEvent,
}) {
  const limitTitle = (inputString, maxLength = 26) => {
    if (inputString?.length > maxLength) {
      return inputString?.slice(0, maxLength) + "...";
    } else {
      return inputString;
    }
  };

  return (
    <div
      className={`${
        currentThread?.message_id === message?.message_id
          ? "bg-white"
          : "bg-[#F9F9F9]"
      }
      ${
        pinned?.message_id === message?.message_id
          ? "shadow-lg sticky top-2 z-[1]"
          : ""
      } 
      w-full shadow-md mt-2 p-2`}
    >
      <div className="flex">
        <div className="mr-5 flex flex-col items-center">
          <Avatar sx={{ width: 55, height: 55 }}>
            {getInitials(message?.from_name)}
          </Avatar>

          <span className="text-[13.9px] text-center block mt-1 w-[75px]">
            {isMine(message) ? "You" : limitTitle(message?.from_name, 10)}
          </span>
          <span className="text-[10px] block text-[#213E98] mt-1 text-center">
            {message?.from_dealer_name}
          </span>
        </div>

        <div className="w-full">
          <div className="flex justify-between">
            <Typography variant="h4" color={"#0D6EFF"}>
              <span className="text-black">Interested in </span>
              <span>{limitTitle(message?.title, 40)}</span>
            </Typography>
            <Typography>
              {formatFromNow(
                message?.counter_offer_id
                  ? message?.counter_offer_date
                  : message?.message_date
              )}
            </Typography>
          </div>

          {message?.offer_token ? (
            <div
              className={`${
                currentThread?.inventory_id === message?.inventory_id
                  ? "bg-[#F9F9F9]"
                  : "bg-white"
              } w-[425px] h-[87px] mt-2 bg-[#F9F9F9] rounded-[8.2px] flex flex-col items-center justify-center shadow-md`}
            >
              <span className="text-[#213E98] text-[18.6px] font-medium">
                {isMine(message)
                  ? `You Sent an ${
                      message?.counter_offer_id ? "Counter Offer" : "Offer"
                    }`
                  : `${message?.from_name} Sent You an ${
                      message?.counter_offer_message ? "Counter Offer" : "Offer"
                    }`}
              </span>
              <span className="text-[#ED1D24] text-[37.2px] font-bold leading-8">
                {formatDollarAmount(
                  message?.counter_offer_id
                    ? message?.counter_offer_amount
                    : message?.offer_amount
                )}
              </span>
            </div>
          ) : (
            <p className="pr-10 text-sm mt-2 leading-4">{message?.message}</p>
          )}
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <span className="text-[#E9392C]">
          {message?.unread_messages > 1
            ? `${message?.unread_messages} unread messages`
            : ""}
        </span>
        <span className="text-[#E9392C]">
          {message?.unread_messages === 1
            ? `${message?.unread_messages} unread message`
            : ""}
        </span>

        {currentThread?.message_id !== message?.message_id ? (
          <button onClick={() => handleEvent("openThread")} className="ml-auto">
            <span className="text-[#0D6EFF]">click to open thread</span>
          </button>
        ) : (
          <div className="ml-auto">
            <span className="text-[#E9392C] text-[15px]">CURRENT THREAD </span>

            {currentThread?.message_id === pinned?.message_id ? (
              <button onClick={() => handleEvent("unpinMessage")}>
                <img src={PinIcon} alt="Pin Icon" className="w-5 h-5 inline" />
              </button>
            ) : (
              <button onClick={() => handleEvent("pinMessage")}>
                <img
                  src={UnpinIcon}
                  alt="Unpin Icon"
                  className="w-5 h-5 inline"
                />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
