import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import styled from "@emotion/styled";
import SearchBox from './components/SearchBox';
import FilterPanel from './components/FilterPanel';

const PREFIX = 'Search'

const classes = {
    card: `${PREFIX}-card`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.card}`]: {
            marginBottom: 30,
            boxShadow: '0px 4px 31px rgb(0 0 0 / 5%)',
            borderRadius: 10,
        }
    }
});

const Search = ({
    resultType,
    setPage
}) => {
    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SearchBox resultType={resultType}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <FilterPanel
                                            resultType={resultType}
                                            setPage={setPage}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </StyledGrid>
    )
}

export default Search;