import { Typography, Avatar } from "@mui/material";
import {useEffect, useState} from "react";
import dongleIcon from "components/TextEditor/icons/dongle.svg";
import searchIcon from "components/TextEditor/icons/search2.svg";
import TextEditor from "components/TextEditor/TextEditor";
import { useDispatch, useSelector } from "react-redux";
import { loadMessages, loadMessageThread, addMessage } from "data/user.actions";
import Spinner from "components/Spinner";
import { formatFromNow } from 'utils/Formatters';
import { Formik } from "formik"
import TextField from "components/widgets/TextField"
import Button from "components/widgets/Button"
import OfferInMessage from "components/OfferInMessage";
import { orderMessage, orderOfferMessage } from "data/user.slice";
import ApproveOfferDialog  from "views/Tasks/components/dialogs/ApproveOfferDialog"
import CounterOfferDialog from "views/Tasks/components/dialogs/CounterOfferDialog";
import OfferDetailsDialog from "views/Tasks/components/dialogs/OfferDetailsDialog";
import DeclineOfferDialog from "views/Tasks/components/dialogs/DeclineOfferDialog";
import MessageList from "./components/MessageList";
import { getInitials } from "utils/User";
import CounterOffers from "./components/CounterOffers";

const MessagesView = () => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [currentThread, setCurrentThread] = useState(null);
  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState(null);
  const [showDialog, setShowDialog] = useState("")
  const [selectedOffer, setSelectedOffer]  = useState(null)
  const [isMessageTab, setIsMessageTab] = useState(true)
  const [pinnedMessage, setPinnedMessage] = useState(null)
  const [pinnedOffer, setPinnedOffer] = useState(null)
  const [currentOfferThread, setCurrentOfferThread] = useState(null)

  const {messages, offerMessages, messageThread, offerThread, processing } = useSelector((state) => state.user);
  const {user} = useSelector((state) => state.authentication)

  useEffect(() => {
    if (messages === undefined && !processing) {
      setIsFetching(true);
      if (isFetching){
  dispatch(loadMessages());
  setIsFetching(false);
}
    }
  }, [messages, processing, isFetching])


const isMine = (message) => {
  if ((message?.from_uid === user?.uid ) || (message?.from_user_id === user?.id) || (message?.from_rooftop_id === Number(user?.rooftop_id)) ){
    return "mine"
  }
  return ""
}

const openThread = (message, fromIndex) => {
  if(isMessageTab) {
  dispatch(loadMessageThread({ message }));
  setCurrentThread(message);
  pinMessage(message, fromIndex);

  } else {
  dispatch(loadMessageThread({ message }));
  setCurrentOfferThread(message);
  pinMessage(message, fromIndex);
  }

};

const pinMessage = (message, fromIndex) => {
  if (isMessageTab){
    setPinnedMessage(message);
    const newOrderedMessages = [...messages];
    const [movedMessage] = newOrderedMessages.splice(fromIndex, 1);
    newOrderedMessages.splice(0, 0, movedMessage);
    dispatch(orderMessage({ messages: newOrderedMessages }));

  } else {
    setPinnedOffer(message);
    const newOrderedOffers = [...offerMessages];
    const [movedOffer] = newOrderedOffers.splice(fromIndex, 1);
    newOrderedOffers.splice(0, 0, movedOffer);
    dispatch(orderOfferMessage({offerMessages: newOrderedOffers}))
  }
  
};

const unpinMessage = () => {
  if (isMessageTab){
    setPinnedMessage(null);
    const newOrderedMessages = [...messages];
    const newOrder = newOrderedMessages.sort(
      (a, b) => new Date(b.message_date) - new Date(a.message_date)
    );  

  dispatch(orderMessage({ messages: newOrder }))
} else {

  setPinnedOffer(null);
  const newOrderedOffers = [...offerMessages];
  const newOrder = newOrderedOffers.sort(
    (a, b) => new Date(b.message_date) - new Date(a.message_date)
  ); 
  dispatch(orderOfferMessage({offerMessages: newOrder}))
}
};

const handleDialogEvent = (event, offer) => {
  setShowDialog(event)
  setSelectedOffer(offer)
 }

const handleMessageEvent = ({event, fromIndex, message}) => {
  if(event === "openThread"){
    openThread(message, fromIndex)
  } else if (event === "pinMessage") {
    pinMessage(message, fromIndex)
  } else if (event === "unpinMessage") {
    unpinMessage()
  }
}

  const handleSubmit = (values) => {
    const newValues = {
      from_rooftop_id: currentThread.to_rooftop_id,
      to_rooftop_id: currentThread.from_rooftop_id,
      inventory_token: currentThread.inventory_token,
      message: values.message,
      threadId: currentThread.message_id
    };
    dispatch(addMessage(newValues));
  }
  
  return (
    <div className="mt-5 ">
      <Typography variant="h1" color={"#213E98"}>
        MESSAGES
      </Typography>

      <div className="flex mt-5">
        <button onClick={() => setIsMessageTab(true)} 
          className={`${isMessageTab ? "bg-[#213E98] text-white" : "bg-[#989BA03D]"} rounded-md shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] font-medium text-[13px] tracking-[.73px] w-[90px] h-[36px] mr-2`}
          >Messages</button>
        <button onClick={() => setIsMessageTab(false)} 
          className={`${isMessageTab ? "bg-[#989BA03D] text-[#213E98]" : "bg-[#213E98] text-white"} rounded-md shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] font-medium text-[13px] tracking-[.73px] w-[90px] h-[36px]`}
          >Offers</button>
      </div>
      <div className="flex mt-5">
        <div className="w-1/2">
          <div className="flex shadow-[0px_2.78px_5.56px_0px_rgba(0,_0,_0,_0.25)] items-center">
            <button
              type="button"
              className="bg-[#F7F7F7] flex h-10 items-center pr-8"
            >
              <img src={dongleIcon} alt="dongle" className="mr-3 ml-2" />
              <Typography variant="h4" color={"#E9392C"}>
                Newest
              </Typography>
            </button>
            <div className="flex ml-2 w-full">
              <img src={searchIcon} alt="search" className="w-9 h-9" />
              <input placeholder="Search by subject..." className="w-full" />
            </div>
          </div>

          <div className="overflow-y-auto h-[660px]">

            {isMessageTab &&
            messages &&
            messages?.map((message, index) => {
              return <MessageList index={index} key={index} 
              message={message} 
              currentThread={currentThread} 
              isMine={isMine} 
              pinned={pinnedMessage} 
              isMessages={true} 
              handleEvent={(event) => handleMessageEvent({event, fromIndex: index, message})}
              />
            }) }

            {!isMessageTab && 
              offerMessages && offerMessages?.map((offer, index) => {
                  return  <MessageList index={index} key={index} message={offer} currentThread={currentOfferThread} messages={offerMessages} setCurrentThread={setCurrentOfferThread} isMine={isMine} pinned={pinnedOffer}  setPinned={setPinnedOffer} handleMessageEvent={handleMessageEvent} handleEvent={(event) => handleMessageEvent ({event, fromIndex: index, message: offer})}  />
                }) } 
          </div>
        </div>

        <div className="w-1/2 ml-9 ">
          {isMessageTab && <div className="shadow-[0px_2.78px_5.56px_0px_rgba(0,_0,_0,_0.25)] p-2">
            {currentThread ?
             <Typography variant="h4" color={"#0D6EFF"}>
             <span className="text-black">Interested in{" "}</span>
             <span>{currentThread.title}</span>
           </Typography> : <Typography variant="h4" color="black" fontWeight={500}>
              Select a message to view
            </Typography>}
          </div>}

          {!isMessageTab &&<div className="shadow-[0px_2.78px_5.56px_0px_rgba(0,_0,_0,_0.25)] p-2">
            {currentOfferThread ?
             <Typography variant="h4" color={"#0D6EFF"}>
             <span className="text-black">Interested in{" "}</span>
             <span>{currentOfferThread.title}</span>
           </Typography> : <Typography variant="h4" color="black" fontWeight={500}>
              Select a offer to view
            </Typography>}
          </div>}

          <div className="h-[480px] overflow-y-auto">
           {isMessageTab && messageThread !== undefined && currentThread !== null && messageThread?.map((message) => {
            return <>
            {!message?.read && message?.id === firstUnreadMessageId && <div className="flex items-center">
                  <div className="w-1/2 h-[0px] border border-[#E9392C]" />
                  <div className="text-[#E9392C] text-[19px] text-center px-3 min-w-max">NEW MESSAGES</div>
                  <div className="w-1/2 h-[0px] border border-[#E9392C]" />
            </div>}
            <div key={message?.inventory_id} className="mt-2 flex py-2 pr-4 ">
               <Avatar sx={{width: 50, height: 50, marginRight: "12px", marginTop: "8px"}}>{getInitials(message?.from_name)}</Avatar>
               <div className="w-full">
               <div className="flex items-center">
                <span className="mr-2 font-medium">{ isMine(message) ? "You" : message?.from_name}</span>
                <span className="text-[#213E98] text-[13.9px]">{formatFromNow(message?.message_date)}</span>
               </div>
                <p className="text-sm mt-1 leading-4">{message?.message}</p>
               </div>
            </div> 
            </>
           })}

        {!isMessageTab &&offerThread  !== undefined && currentOfferThread !== null && offerThread?.map((message, index) => {
            return <>
            {!message?.read && message?.id === firstUnreadMessageId && <div className="flex items-center">
                  <div className="w-1/2 h-[0px] border border-[#E9392C]" />
                  <div className="text-[#E9392C] text-[19px] text-center px-3 min-w-max">NEW MESSAGES</div>
                  <div className="w-1/2 h-[0px] border border-[#E9392C]" />
            </div>}
            <div key={message?.inventory_id} className="mt-2 flex py-2 pr-4 ">
               <Avatar sx={{width: 50, height: 50, marginRight: "12px", marginTop: "8px"}}>{getInitials(message?.from_name)}</Avatar>
               <div className="w-full">
               <div className="flex items-center">
                <span className="mr-2 font-medium">{ isMine(message) ? "You" : message?.from_name}</span>
                <span className="text-[#213E98] text-[13.9px]">{formatFromNow(message?.message_date)}</span>
               </div>
 
            {/* {index === 0 &&  */}
             <OfferInMessage message={message} isMine={isMine(message)} 
                handleEvent={(event) => handleDialogEvent(event, message)} />
                 {/* } */}
              {message?.counter_offer_id && 
               <CounterOffers message={message} isMine={isMine(message)} 
                  handleEvent={(event) => handleDialogEvent(event, message)} />}
               </div>
            </div> 
            </>
           })}
          </div>
          
          {/* <TextEditor handleSubmit={handleSubmit} /> */}

          {isMessageTab && <Formik
              initialValues={{}}
              enableReinitialize
              onSubmit={async (values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false);
                actions.resetForm({
                  values: { message: ''},
                });     
              }}
            >
              {({ handleSubmit }) => {
                return (
                  <>
                    <TextField
                      name="message"
                      placeholder="Reply..."
                      multiline
                      rows={5}
                      className="multiline"
                    /> 

                      <Button
                      disabled={currentThread === null || processing }
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </>
                      );
              }}
                    </Formik>}

          <ApproveOfferDialog
            isOpen={(showDialog === "approve_offer" || showDialog === "approve_counter_offer") ? showDialog : false}
            handleClose={() => setShowDialog("")}
            vehicle={selectedOffer}
          />

          <CounterOfferDialog
            isOpen={showDialog === "counter_offer"}
            currentOffer={selectedOffer}
            handleClose={() => setShowDialog("")}
          />

          <DeclineOfferDialog 
            isOpen={showDialog === "decline_offer"}
            offer={selectedOffer}
            handleClose={() => setShowDialog("")}
            />

          <OfferDetailsDialog
            isOpen={showDialog === "offer_details"}
            isDisabled={isMine(selectedOffer) || selectedOffer?.offer_accepted_date}
            vehicleToken={selectedOffer?.inventory_token}
            handleClose={() => setShowDialog("")}
          />
          
        </div>
      </div>
      <Spinner show={processing} />
    </div>
  );
};

export default MessagesView;
