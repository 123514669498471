import React, {useEffect, useState} from 'react';
import { TabPanel } from '@mui/lab';
import RangeSlider from 'components/widgets/RangeSlider';
import {useSelector, useDispatch} from "react-redux";
import {getSearchCriteria, getVehicleOptions} from "../../../data/search.selectors";
import {updateFilter} from "../../../data/search.actions";

const OdometerFilter = ({ searchType}) => {
  const dispatch = useDispatch();
  const relevantCriteria = useSelector( getSearchCriteria( searchType ) );
  const vehicleOption = useSelector(getVehicleOptions);

  const [minOdometer, setMinOdometer] = useState();
  const [maxOdometer, setMaxOdometer] = useState();

  useEffect(() => {
    if (vehicleOption && vehicleOption.mileage) {
      const newMinOdometer = vehicleOption.mileage?.[vehicleOption.mileage?.length - 1]?.mileage;
      const newMaxOdometer = vehicleOption.mileage?.[0]?.mileage;
      setMinOdometer(newMinOdometer);
      setMaxOdometer(newMaxOdometer);
    }
  }, [dispatch, vehicleOption?.mileage]);

  return (
    <TabPanel value='odometer'>
      <RangeSlider
        minValue={minOdometer}
        maxValue={maxOdometer}
        defaultValue={[relevantCriteria?.mileage?.min, relevantCriteria?.mileage?.max]}
        labelText={''}
        handleChange={(value) => {
          const [min, max] = value;
          dispatch(updateFilter({
            value: {[searchType]: {mileage: {min, max}}}
          }));
        }}
        disabled={true}
      />
    </TabPanel>
  );
};

export default OdometerFilter;