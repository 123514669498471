import * as yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import VehicleDetailPanel from "components/vehicleDetailPanel";
import ApproveOfferDialog from "./ApproveOfferDialog";
import { useState } from "react";
import TextField from "components/widgets/TextField";
import Avatar from "@mui/material/Avatar";
import MessagesIcon from "@mui/icons-material/MailOutline";
import starImage from "../../../../icons/star.svg";
import locationImage from "../../../../icons/location.svg";
import EditOfferDialog from "./EditOfferDialog";
import CounterOfferDialog from "./CounterOfferDialog";
import { formatDollarAmount } from "utils/Formatters";
import Spinner from "components/Spinner";
import { useEffect } from "react";
import { vehicleDetails, getTransactionStatus, getConditionReport } from "data/vehicle.actions";
import { getInitials } from "utils/User";

const validationSchema = yup.object().shape({
  counterAmount: yup
    .number()
    .required("Please enter a counter offer amount"),
});

const OfferDetailsDialog = ({
  isOpen,
  isDisabled,
  handleClose,
  vehicleToken,
}) => {
  const dispatch = useDispatch();
  const [showApproveOfferDialog, setShowApproveOfferDialog] = useState(false);
  const [showEditOfferDialog, setShowEditOfferDialog] = useState(false);
  const [showCounterOfferDialog, setShowCounterOfferDialog] = useState(false);
  const [initialCounterValues, setInitialCounterValues] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  const {
      vehicleDetail,
      loadingVehicleDetails,
      loadingTransactionStatuses,
      loadingConditionReport
  } = useSelector(state => state.search);

  const { vehicle, myDealer } = vehicleDetail;

  useEffect(() => {
    if (vehicleToken !== undefined && vehicle?.token !== vehicleToken) {
      const params = {
        token: vehicleToken    
      }
      dispatch(vehicleDetails(params));
      dispatch(getTransactionStatus(params));
      dispatch(getConditionReport(params));
    }
  }, [dispatch, vehicleToken, vehicle]);

  const handleSubmit = (values) => {
    const newValues = {
      counterMessage: values.counterMessage,
      counterAmount: values.counterAmount,
    };
    setInitialCounterValues(newValues);
    setShowCounterOfferDialog(true);
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { maxWidth: "100vw" } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box onClick={handleClose} className='float-right cursor-pointer'>
          <Typography variant="h4">X</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            <div className="mx-auto">
              <h3 className="uppercase tracking-[4.21px] text-[#253241] text-center font-bold text-[33.7px]">
                Offer Details
              </h3>

              <div className="flex mt-[35px] ">
                <div
                  className="h-[295px] w-[650px] overflow-scroll shadow-md"
                >
                { <VehicleDetailPanel id={vehicle?.inventory_token} />}
                </div>

                <div className="w-[370px] ml-4">
                  <div className="flex justify-between">
                    <div className="bg-[#E9392C] text-white rounded-lg   pb-[14px] pt-6 p-4  flex flex-col items-center justify-center w-fit">
                   
                      <span className="text-[27px] font-medium tracking-[-.23px] leading-3">
                        {formatDollarAmount(vehicle?.price)}
                      </span>

                      <h4 className="text-[14px] tracking-[-.11px] mt-3 ">
                        Retail Price
                      </h4>
                    </div>

                    {/* <div className="bg-[#E9ECF5] text-[#213E98] rounded-lg w-[120px] h-[72px] pl-[14px] pb-[15px] pt-4">
                      <span className="text-[23px] font-medium tracking-[-.23px] leading-3">
                        {formatDollarAmount(vehicle?.kbb)}
                      </span>
                      <h4 className="text-[12px] tracking-[-.11px]">
                        Kelly Blue Book
                      </h4>
                    </div> */}

                    {/* <div className="bg-[#E9ECF5] text-[#213E98] rounded-lg w-[120px] h-[72px] pl-[14px] pb-[15px] pt-4">
                      <span className="text-[23px] font-medium tracking-[-.23px] leading-3">
                        {formatDollarAmount(vehicle?.blackbox)}
                      </span>
                      <h4 className="text-[12px] tracking-[-.11px]">
                        blackbox
                      </h4>
                    </div> */}
                  </div>

                  <div className="bg-[#F9F9F9] rounded-2xl shadow-md w-full text-center mt-3">
                    <div className="text-[#213E98] text-[28.3px] font-medium">
                      Your Offer
                    </div>
                    <div className="text-[#ED1D24] font-bold text-[56.6px]">
                      {formatDollarAmount(vehicle?.offer_amount)}
                    </div>
                  </div>

                  <div className="bg-[#F9F9F9] rounded-2xl mt-3 p-3 pl-4 shadow-[0px_3.6px_3.6px_0px_rgba(0,_0,_0,_0.25)]">
                    <div className="flex justify-between">
                      <span className="text-[#999] text-[15px]">Rooftop</span>
                      <span className="text-[#253241]">
                        {myDealer?.name}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-[#999] text-[15px]">
                        Transportation Method
                      </span>
                      <span className="text-[#253241]">
                        {vehicle?.transportation_method ? vehicle?.transportation_method : "Reinvent Exchange" }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
              <div className="mt-7 flex h-[216px]">
                <div className="w-[370px] bg-[#9ca3b11a] rounded-lg p-4 h-full shadow-[0px_4.37px_4.37px_rgba(0,_0,_0,_0.25)]">
                  <div className="flex items-center">
                    <Avatar sx={{ width: 95, height: 95 }}>
                      {getInitials(myDealer?.name)}
                    </Avatar>
                    <div className="flex flex-col ml-2">
                      <span className="text-[#213E98] font-bold text-[38px] leading-8 mt-2">
                        {myDealer?.name}
                      </span>

                      <div className="flex items-center">
                        <span className="text-[#253241] text-[21.8px] font-medium mr-1">
                          {myDealer?.name}
                        </span>
                        <img src={starImage} alt="Star Icon" className="" />

                        <span className="text-[#253241] text-[17.8px] ml-2">
                          {vehicle?.from_rooftop_rating}
                        </span>
                      </div>
                      <div className="flex">
                        <img src={locationImage} alt="Location Icon" />
                        <span className="text-[#9CA3B1] text-[13.5px] font-medium ml-1">
                          {`${myDealer?.address?.address1}, ${myDealer?.address?.state} ${myDealer?.address?.zipCode}`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button className="bg-[#213E98] rounded-md text-white p-4 mt-2 w-full flex items-center justify-center">
                    <MessagesIcon />
                    <span className="ml-1">View In Messanger</span>
                  </button>
                </div>

                <div className="h-full">
                  <Formik
                    initialValues={{}}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                      handleSubmit(values);
                      actions.setSubmitting(false);
                    }}
                  >
                    {({ handleSubmit, errors, touched }) => {
                      return (
                        <div className="bg-[#F9F9F9] w-[275px] p-3 rounded-[10px] ml-3 h-full shadow-[0px_4.37px_4.37px_rgba(0,_0,_0,_0.25)]">
                          <h4 className="text-[#E9392C] text-[28.7px] font-bold tracking-[1.16px] text-center mt-3">
                            Counter Offer
                          </h4>

                          <TextField
                            name="counterAmount"
                            placeholder="$ Enter your counter amount"
                            className="mt-1 "
                          />

                      {errors.counterAmount &&
                        showErrors &&
                        !touched.counterAmount && (
                          <div className="text-danger text-sm text-center mt-[2px]">
                            {errors.counterAmount}
                          </div>
                        )}
                        
                          <button
                            onClick={ () => {
                              handleSubmit();
                              setShowErrors(true);
                              }}
                              disabled={isDisabled}
                            className="text-[17.9px] text-white bg-[#ED1D24] rounded-md w-full h-[50px] mt-4 font-bold disabled:opacity-20"
                          >
                            Submit
                          </button>
                        </div>
                      );
                    }}
                  </Formik>
                </div>

                <div className="ml-auto h-full flex flex-col">
                  <button
                    onClick={() => setShowApproveOfferDialog(true)}
                    disabled={isDisabled}
                    className="bg-[#213E98] rounded-[9.8px] shadow-[0px_4.38px_4.37px_0px_rgba(0,_0,_0,_0.25)] text-[#F9F9F9] text-[28.7px] font-medium tracking-[1.15px] w-[370px]  disabled:opacity-20 h-[54px]"
                  >
                    Approve Offer
                  </button>
                  <button 
                  disabled={isDisabled}
                  className="bg-[#E9392C] rounded-[9.8px] shadow-[0px_4.38px_4.37px_0px_rgba(0,_0,_0,_0.25)] text-[#F9F9F9] text-[28.7px] font-medium tracking-[1.15px] w-[370px]  mt-3 disabled:opacity-20 h-[54px]">
                    Decline Offer
                  </button>
                </div>
              </div>

            <ApproveOfferDialog
              isOpen={showApproveOfferDialog}
              handleClose={() => setShowApproveOfferDialog(false)}
              vehicle={vehicle}
            />

            <EditOfferDialog
              isOpen={showEditOfferDialog}
              handleClose={() => setShowEditOfferDialog(false)}
              vehicle={vehicle}
            />

            {initialCounterValues?.counterAmount && (
              <CounterOfferDialog
                isOpen={showCounterOfferDialog}
                currentOffer={vehicle}
                handleClose={() => setShowCounterOfferDialog(false)}
                initialCounterValues={initialCounterValues}
              />
            )}
          </div>
        </DialogContentText>
      </DialogContent>
      <Spinner 
      show={loadingVehicleDetails || loadingTransactionStatuses || loadingConditionReport}/>
    </Dialog>
  );
};

export default OfferDetailsDialog;
