import {useSelector} from "react-redux";

export const getAuthentication = (state) => {
  const {
    authentication
  } = state;

  return authentication;
}

export const getUserData = (state) => {
  const { authentication: {user} } = state;

  return user;
}