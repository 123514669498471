/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import Spinner from 'components/Spinner';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import VehiclePanel from "./components/VehiclePanel";
import Search from "components/Search/index";
import Sort from "components/Sort/index";
import VehicleDetailsDialog from "../dialogs/VehicleDetailsDialog";
import LikeDialog from 'components/dialogs/LikeDialog';
import { vehicleSearch } from "data/vehicle.actions";
import { getVehicleSearchData, getUserData, getAuctionData, getSearchCriteria} from "data/selectors";

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        padding: '0 60px',
        [theme.breakpoints.down('md')]: {
            padding: '0 0px',
        },
    }
});

const SearchView = ({ resultType }) => {
    const dispatch = useDispatch();
    const [mergeArray, setMergeArray] = useState(false);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('lowest_price');
    const [selectedVehicle, setSelectedVehicle] = useState()
    const [showLikesDialog, setShowLikesDialog] = useState(null);

    const user = useSelector(getUserData);
    const criteria = useSelector(getSearchCriteria(resultType));
    const search = useSelector(getVehicleSearchData);

    const {
        count,
        vehicles,
        loadingSearchOptions,
        loadingCategoryCount,
        loadingModelOptions,
        searching,
    } = search;

    const auction = useSelector(getAuctionData);
    const { activeAuctionCountLoading } = auction;

    useEffect(() => {
      if (user) {
        setPage(1);

        dispatch(vehicleSearch({
          ...criteria,
          dealer: user.dealer_id,
          rooftop: user.rooftop_id,
          sort,
          page,
          resultType
        }));
      }
    }, [criteria, user, sort, page, resultType]);

    const fetchItems = useCallback(
        async () => {
            if (searching) {
                return;
            }

            if (count >= page * 20) {
              setPage(page + 1);
            }
        },
        [count, page]
    );

    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Search
                    setPage={setPage}
                    resultType={resultType}
                />
                {count > 0 && (
                    <Typography variant="h6" color='primary'>Found {count} Vehicle{count > 1 ? 's' : ''}</Typography>
                )}
                <Sort
                    sort={sort}
                    setSort={setSort}
                    resultType={'searchPanel'}
                />
                <InfiniteScroll
                    dataLength={20 * page} //This is important field to render the next data
                    next={fetchItems}
                    scrollThreshold={1}
                    hasMore={true}
                    loader={20 * page <= count ? <h4>Loading...</h4> : ''}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You have seen it all</b>
                        </p>
                    }
                >
                    {vehicles && vehicles?.map((vehicle) => {
                        return (
                            <VehiclePanel
                                key={vehicle.id}
                                vehicle={vehicle}
                                resultType={resultType}
                                showDetailsHandler={() => setSelectedVehicle(vehicle)}
                                showLikeDialog={() => setShowLikesDialog(vehicle?.token)}
                            />
                        );
                     })
                    }
                </InfiniteScroll>

                {/*{error !== undefined ? <div>search error: {error}</div> : (count === 0 ? <div>Record Not Found</div> : '')}*/}
            </Grid>

            <VehicleDetailsDialog
                isOpen={selectedVehicle !== undefined}
                handleClose={() => setSelectedVehicle(undefined)}
                vehicleToken={selectedVehicle?.token}
            />

            <LikeDialog 
              isOpen={showLikesDialog !== null}
              handleClose={() => setShowLikesDialog(null)}
              vehicleToken={showLikesDialog}
            />

            <Spinner show={
                loadingSearchOptions ||
                searching ||
                loadingCategoryCount ||
                loadingModelOptions ||
                activeAuctionCountLoading
            } />


        </StyledGrid>
    )
}

export default SearchView;