

export const getSearchCriteria = (searchType) => {

  switch(searchType) {
    case 'buyPage':
      return (state) => {
        const {
          searchCriteria: {
            buyPage: buyPageCriteria,
          }
        } = state;

        return buyPageCriteria;
      }
    case 'sellPage':
      return (state) => {
        const {
          searchCriteria: {
            sellPage: sellPageCriteria,
          }
        } = state;

        return sellPageCriteria;
      }
    default:
      return (state) => {
        return {}
      }
  }
}

export const getVehicleSearchData = (state) => {
  const {
    search
  } = state;

  return search;
}

export const getVehicleOptions = (state) => {
  const {
    search: { vehicleOption }
  } = state;

  return vehicleOption;
}
