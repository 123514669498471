import React from 'react';
import Grid from '@mui/material/Grid';
import { TabPanel } from '@mui/lab';
import {useDispatch, useSelector} from 'react-redux';
import { selectMultipleOption } from 'utils/Filter';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';
import {getSearchCriteria, getVehicleOptions} from "../../../data/search.selectors";
import {updateFilter} from "../../../data/search.actions";

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const FuelTypeFilter = ({ searchType }) => {
  const dispatch = useDispatch();

  const criteria = useSelector( getSearchCriteria( searchType ) );
  const vehicleOption = useSelector(getVehicleOptions);

  return (
    <TabPanel value='fueltype'>
      <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                criteria.fuelTypes.length === 0
                  ? classes.btnColor
                  : classes.styledBtn
              }
              color='primary'
              size='large'
              value=''
              onClick={(e) => {
                dispatch(updateFilter({
                  value: {[searchType]: {fuelTypes: e.target.value}}
                }));
              }}
            >
              All
            </StyledOptionButton>
          </Grid>
          {vehicleOption.fuelTypes?.map((fuel) => (
            <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  criteria.fuelTypes.includes(fuel.fuelType)
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={fuel.fuelTypes}
                onClick={(e) => {
                  dispatch(updateFilter({
                    value: {[searchType]: {fuelTypes: e.target.value}}
                  }));
                }}
              >
                {fuel.fuelType} ({fuel.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
      </StyledFilterGrid>
    </TabPanel>
  );
};

export default FuelTypeFilter;