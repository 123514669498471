import styled from "@emotion/styled";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/widgets/Button";
import TextField from "components/widgets/TextField";
import { toast } from "react-toastify";
import { useState } from "react";
import { formatDollarAmount } from "utils/Formatters";

const PREFIX = "LikeDialog";

const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    [`& .${classes.closeButton}`]: {
      float: "right",
      cursor: "pointer",
    },
  };
});

const EditOfferDialog = ({ isOpen, handleClose, vehicle }) => {
  const handleSubmit = () => {};
  return (
    <StyledDialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { maxWidth: "100vw" } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box onClick={() => handleClose()} className={classes.closeButton}>
          <Typography variant="h4">X</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            <Formik
              initialValues={{}}
              onSubmit={async (values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false);
              }}
            >
              {({ handleSubmit }) => {
                return (
                  <div className="w-[600px] mx-auto px-8">
                    <h3 className="uppercase font-bold text-[30.9px] tracking-[3.86px] text-[#253241] text-center ">
                      Edit Offer
                    </h3>

                    <div className="mt-5 flex  justify-between">
                      <div className="w-[260px] rounded-lg bg-[#F9F9F9] text-center py-2 ">
                        <div className="text-[#213E98] text-[17.8px] font-medium ">
                          Current Offer
                        </div>
                        <div className="text-[#ED1D24] font-bold text-[35.7px] ">
                          {formatDollarAmount(vehicle.offer_amount)}
                        </div>
                      </div>

                      <div className="w-[260px] rounded-lg bg-[#F9F9F9] text-center p-2 pb-1 ">
                        <div className="text-[#213E98] text-[17.8px] font-medium">
                          Enter New Offer
                        </div>
                        <TextField
                          name="editedOfferAmount"
                          placeholder="$0.00"
                          className="bg-white"
                        />
                      </div>
                    </div>

                    <div className="mt-5 bg-[#9CA3B11A] p-5 w-[540px] rounded-lg">
                      <TextField
                        name="editedOfferComment"
                        placeholder="Tell the people why you are editing your offer"
                        multiline
                        rows={7}
                        className="bg-white text-[18px] text-[#495057] "
                      />
                    </div>

                    <div className="flex justify-between mt-6">
                      <button className="bg-[#E9392C] w-[260px] h-[50px] rounded-lg text-white font-medium shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)]">
                        Go Back
                      </button>

                      <button className="bg-[#213E98] w-[260px] h-[50px] rounded-lg text-white font-medium shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)]">
                        Submit
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </DialogContentText>
      </DialogContent>
    </StyledDialog>
  );
};

export default EditOfferDialog;
