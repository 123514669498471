import styled from "@emotion/styled";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/widgets/Button";
import TextField from "components/widgets/TextField";
import { addMessage, toggleLikes } from "data/user.actions";
import { toast } from "react-toastify";
import ChangeRooftop from "components/ChangeRooftop";

const PREFIX = "LikeDialog";

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  submit: `${PREFIX}-submit`,
  closeBtn: `${PREFIX}-closeBtn`,
};

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    [`& .${classes.closeButton}`]: {
      float: "right",
      cursor: "pointer",
    },
    [`& .${classes.submit}`]: {
      float: "right",
      marginTop: 10,
    },
    [`& .${classes.closeBtn}`]: {
      float: "right",
      marginTop: 10,
      marginRight: 10,
    },
  };
});

const MessageDialog = ({isOpen, vehicleToken, toRooftopId, handleClose}) => {

    const dispatch = useDispatch()
    const {error} = useSelector((state) => state.user)
    const { user } = useSelector(state => state.authentication);
    const {processing: processingLocations, locations, rooftopsForUser,  error: locationError} = useSelector(state => state.location)

    const handleSubmit = (values) => {
      const newValues = {
        from_rooftop_id: values.fromRooftopId,
        to_rooftop_id: toRooftopId,
        inventory_token: vehicleToken,
        message: values.message,
      }
      dispatch(addMessage(newValues))

      if (error) {
        toast.error(error);
      } else {
        toast.success("Message was succesfully sent");
      }
      handleClose();
    }

  return (
    <StyledDialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { maxWidth: "100vw" } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box onClick={() => handleClose()} className={classes.closeButton}>
          <Typography variant="h4">X</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            <Formik
              initialValues={
                {fromRooftopId: parseInt(user?.rooftop_id),
                 message: ""}}
              onSubmit={async (values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false);
              }}
            >
              {({ handleSubmit, setFieldValue }) => {
                return (
                  <>
                    <ChangeRooftop  
                      initialValue={{ rooftop: parseInt(user?.rooftop_id)}}
                      rooftops={rooftopsForUser}
                      handleSubmit={(values) => setFieldValue("fromRooftopId", parseInt(values.rooftop))}
                      label={"Current Rooftop"}
                    />
                    <TextField
                      label="Please leave a message for the dealer"
                      name="message"
                      multiline
                      rows={5}
                      className="multiline"
                    />
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.submit}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.closeBtn}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </div>
        </DialogContentText>
      </DialogContent>
    </StyledDialog>
  )
}

export default MessageDialog